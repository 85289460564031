import styled from 'styled-components'
import colors from '../../assets/style/colors'
import BackImage from '../../assets/images/back.jpg'

const ViewRecoveryItemStyle = styled.div`
	min-height: 100vh;
	padding: 40px;
	//background-image: url(${BackImage});
	background-size: cover;
	box-sizing: border-box;
	background-position: center center;
	display: flex;
	justify-content: center;
	gap: 15px;

	.container {
		//display: flex;
		//flex-wrap: wrap;
		//box-shadow: 3px 3px 10px #cfcfcf;
		//border-radius: 10px;
		//background-color: #fffffff2;
		//padding: 10px;
		//min-height: 90vh;
		//align-items: flex-start;
		//justify-content: space-between;

		.betweenCard {
			width: 30%;
			display: flex;
			flex-wrap: wrap;
			position: relative;
			.monthCard {
				display: flex;
				width: 100%;
			}
			.relative {
				width: 100%;
				box-sizing: border-box;
				padding-right: 20px;
				.none {
					display: none;
				}
				.absolutCard {
					position: absolute;
					bottom: 20px;
					left: 0;
					background-color: yellow;
					width: 100%;
					height: 200px;
					overflow: auto;
					.absoluteButton {
						background-color: transparent;
						border: 1px solid #d7d7d7;
						width: 100%;
					}
				}
				.input {
					border: 2px solid ${colors.gray};
					font-size: 15px;
					padding: 10px;
					border-radius: 5px;
					color: ${colors.mainColor};
					width: 100%;
					margin-bottom: 50px;
				}
			}

			.userSearchCard {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				width: 100%;
				.createUserBtn {
					padding: 11px 10px;
					margin-left: 5px;
					border-radius: 5px;
					background-color: ${colors.mainColor};
					font-size: 15px;
					color: #ffffff;
					border: none;
					width: 50px;
				}
			}
			.inputDefault {
				border: 2px solid ${colors.gray};
				font-size: 15px;
				padding: 10px;
				border-radius: 5px;
				color: ${colors.mainColor};
				width: 100%;
				margin-bottom: 35px;
				background-color: #ececec;
			}
			.inputDefault2 {
				border: 2px solid ${colors.gray};
				font-size: 15px;
				padding: 10px;
				border-radius: 5px;
				color: ${colors.mainColor};
				width: 85%;
				margin-bottom: 10px;
				background-color: #ececec;
			}
			.inputCard2 {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
			}
			.table {
				border-collapse: collapse;
				margin-top: 20px;
				background-color: #ffffff;
				width: 100%;
				td,
				th {
					border: 1px solid ${colors.gray};
					padding: 5px;
					text-align: center;
				}
				.inputStartPrice {
					background-color: #ffffff;
					border: 1px solid #c4c4c4;
					outline: none;
					width: 50px;
				}
			}
			.center {
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
		}
		.card2 {
			width: 50%;
			display: flex;
			justify-content: center;
			.input {
				border: 2px solid ${colors.gray};
				font-size: 15px;
				padding: 10px;
				border-radius: 5px;
				color: ${colors.mainColor};
				width: 60%;
			}
		}
		.bottomCard {
			display: flex;
			width: 100%;
			background-color: aliceblue;
			.cancelBtn {
				background-color: red;
				margin: 50px;
				padding: 100px;
			}
		}
	}
	.input {
		border: 2px solid ${colors.gray};
		font-size: 15px;
		padding: 10px;
		border-radius: 5px;
		color: ${colors.mainColor};
		width: 100%;
		margin-bottom: 35px;
	}
	.input1 {
		border: 2px solid ${colors.gray};
		font-size: 15px;
		padding: 10px;
		border-radius: 5px;
		color: ${colors.mainColor};
		width: 85%;
		margin-bottom: 35px;
	}
	.fio {
		padding: 0;
		margin: 0;
		font-size: 14px;
		margin-bottom: 2px;
	}
	.comment {
		padding: 0;
		margin: 0;
		margin-top: -30px;
		text-align: left;
		font-size: 20px;
		margin-bottom: 2px;
		width: 100%;
		font-weight: bold;
	}
	.cardmini {
		width: 95%;
	}
	.debt {
		color: red;
	}
	.betweenCard2 {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	.sendComment {
		width: 50px;
		border-radius: 5px;
		height: 40px;
		margin-left: 10px;
	}
	.commentSection {
		width: 100%;
		flex-direction: row;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
`
export default ViewRecoveryItemStyle
