import React, { useEffect, useRef, useState } from 'react'
import AllProductsStyle from '../AllProducts/AllProducts'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import ReportsModal from '../../components/Modal/ReportsModal/index';

const handleBusinessPartner = _.debounce((searchFunc, value) => {
	searchFunc(value)
}, 1000)

const WhsReports = () => {

	const {
		client: clientStore,
		imei: imeiStore,
		cardCode: cardCodeStore,
	} = useSelector((state) => state.cashPayment)
	const dispatch = useDispatch()

	const navigation = useNavigate()
	const errorRef = useRef()
  const viewRef = useRef()



	const [isLoading, setIsLoading] = useState(false)
	const [clientLoading, setClientLoading] = useState(false)
	const [client, setClient] = useState(clientStore)
	const [clientCode, setClientCode] = useState(cardCodeStore)
	const [imeiClient, setImeiClient] = useState(imeiStore)
	const [product, setProduct] = useState('')
	const [data, setData] = useState([])
	const [currentPage, setCurrentPage] = useState(0)


	const search = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getSerialsInStock`, {
				headers: {
					Prefer: 'odata.maxpagesize=40000',
				},
			})
			.then((res) => {
				const resData = res.data.value || []
        let a = {};
        resData.forEach(item => {
          if (!a[item.ItemCode]) {
            a[item.ItemCode] = []
          }
          a[item.ItemCode].push(item);
        });
        let arr = Object.keys(a)
          .map(ItemCode => ({
            ItemCode,
            ItemName: a[ItemCode][0].ItemName,
            data: a[ItemCode]
          }));
				setData(arr)
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err)) errorRef.current?.open(get(err, 'message', ''))
				setIsLoading(false)
			})
	}

	// const businessPartners = async (value) => {
	// 	setClientLoading(true)
	// 	api
	// 		.get(`BusinessPartners`, {
	// 			params: {
	// 				$select: 'CardCode, CardName, U_Telephone',
	// 				$filter: `contains(CardName, '${value}') or contains(U_Telephone, '${value}') or contains(CardCode, '${value}')`,
	// 			},
	// 			headers: {
	// 				Prefer: 'odata.maxpagesize=10',
	// 			},
	// 		})
	// 		.then((res) => {
	// 			setClientsData(JSON.parse(res.data).value || [])
	// 			setClientLoading(false)
	// 		})
	// 		.catch((err) => {
	// 			if (isAxiosError(err)) errorRef.current?.open(get(err, 'message', ''))
	// 			setIsLoading(false)
	// 		})
	// }



	useEffect(() => {
		search()
	}, [currentPage])

	const viewItem = (v) => {
		viewRef.current?.open(v)
	}

	const newDatas = () => {
		if (data.length >= 10) {
			setCurrentPage(currentPage + 10)
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const oldData = () => {
		if (data.oldPage < 0) {
			alert('boshqa malumot yoq')
		} else {
			setCurrentPage(currentPage - 10)
		}
	}

	// const handleSearch = () => {
	// 	search()
	// }

	return (
		<Layout>
			<AllProductsStyle>
				<div className="container">
					<p className="productTitle">Склад остатка</p>
					{/* <div className="grid sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-6 gap-4 my-8">
						<div className="relative">
							<input
								className={'border-[1px] border-[#d9d9d9] p-2 rounded-md '}
								type="text"
								placeholder="Клиент"
								value={client}
								onChange={(e) => {
									setClient(e.target.value)
									handleBusinessPartner(businessPartners, e.target.value)
								}}
							/>
							{client && (
								<div className="absolute top-10 left-0 bg-white w-full z-10 border-[1px] border-[#d9d9d9] max-h-[300px] overflow-y-scroll">
									{clientsData.map((v, i) => {
										return (
											<div
												key={i}
												className="p-2 border-b cursor-pointer hover:bg-gray-100"
												onClick={() => {

													setClientCode(v.CardCode)
													setClient(v.CardName)
													setClientsData([])
												}}
											>
												{v.CardName}
											</div>
										)
									})}
								</div>
							)}
						</div>
						<input
							className={'border-[1px] border-[#d9d9d9"] p-2 rounded-md '}
							type="text"
							placeholder="Имеи"
							defaultValue={imeiClient}
							onChange={(v) => {
								setImeiClient(v.target.value)
							}}
						/>
						{/*<input*/}
						{/*  className={'border-[1px] border-[#d9d9d9"] p-2 rounded-md '}*/}
						{/*  type="text"*/}
						{/*  placeholder="Товар"*/}
						{/*  defaultValue={product}*/}
						{/*  onChange={(v) => setProduct(v.target.value)}*/}
						{/*/>*/}
						{/* <Button onClick={() => handleSearch()} isLoading={isLoading}>
							Поиск
						</Button> */}
					{/* </div> */}

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto mb-8 mt-10">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												Код Товара
											</th>
											<th scope="col" className="px-6 py-4">
                      Товар
											</th>
											<th scope="col" className="px-6 py-4">
                      IMEI
											</th>
											<th scope="col" className="px-6 py-4">
												Цена
											</th>
											<th scope="col" className="px-6 py-4">
                      От какого продавца
											</th>
											<th scope="col" className="px-6 py-4">
												Имя склада
											</th>
										</tr>
									</thead>
									<tbody>
										{data.map((v, i) => {
											return (
												<tr
													key={i}
													onClick={() => viewItem(v)}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													<td
														scope="row"
														className="px-6 py-4 font-medium text-gray-900 "
													>
														{get(v, 'ItemCode', '-')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'ItemName', '-')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'IntrSerial', '-')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'CostTotal', '-')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'CardName', '-')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'WhsName', '-')}
													</td>

												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
							<div className="topCard">
								<div className="flex items-center gap-2 ">
									<Button
										disabled={currentPage === 0}
										className={'btn'}
										onClick={oldData}
									>
										{'<'}
									</Button>
									<p>{currentPage + 1}</p>
									<Button
										disabled={data.length < 10}
										className={'btn'}
										onClick={newDatas}
									>
										{'>'}
									</Button>
								</div>
								{/* <Button
									className={'btn'}
									onClick={() => window.open('/createProducts-payment','_blank')}
								>
									{'+'}
								</Button> */}
							</div>
						</>
					)}
				</div>
			</AllProductsStyle>
			<>
      <ReportsModal
				getRef={(ref) => (viewRef.current = ref)}
				onUpdated={() => search(data.currentPage, data.data)}
			/>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default WhsReports
