import React, { useEffect, useRef, useState } from 'react'
import Purchases from './Purchases'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import moment from 'moment'

const PurchasesPage = () => {
	const navigation = useNavigate()
	const errorRef = useRef()

	const [isLoading, setIsLoading] = useState(false)
	const [startDate, setStartDate] = useState(
		new Date().toISOString().split('T')[0],
	)

	const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0])
	const [data, setData] = useState({
		data: [],
	})
	const [pagination, setPagination] = useState({
		page: 0,
	})
	const search = () => {
		setIsLoading(true)
		customFuncsApi
			.get(
				`xsSql/getLastPurchases?date1='${startDate + 'T00%3A00%3A00.000Z'}'&date2='${endDate + 'T00%3A00%3A00.000Z'}'&$skip=${pagination.page * 20}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=20',
					},
				},
			)
			.then((res) => {
				const resData = res.data.value

				setData({
					data: [...resData],
				})
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		search(0, 0)
	}, [startDate, startDate, endDate, pagination])

	const newDatas = () => {
		if (data.data.length < 20) {
			alert('Никакой другой информации')
		} else {
			setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
		}
	}

	const oldData = () => {
		if (pagination.page > 0) {
			setPagination((prev) => ({ ...prev, page: prev.page - 1 }))
		} else {
			alert('Никакой другой информации')
		}
	}

	const handleStartDateChange = (event) => {
		setStartDate(event.target.value)
	}

	const handleEndDateChange = (event) => {
		setEndDate(event.target.value)
	}

	return (
		<Layout>
			<Purchases>
				<div className="container">
					<p className="productTitle">Покупки</p>
					<div className={'flex justify-between pr-5'}>
						<div className="searchCard">
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Дата начала
								</p>
								<input
									className={
										'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
									}
									type="date"
									value={startDate}
									onChange={handleStartDateChange}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Дата окончания
								</p>
								<input
									className={
										'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
									}
									type="date"
									value={endDate}
									onChange={handleEndDateChange}
								/>
							</div>
						</div>
						<Button
							className={'btn'}
							onClick={() => window.open('/create-purchases','_blank')}
						>
							{'+ Добавить'}
						</Button>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto my-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												ПОСТАВЩИК
											</th>
											<th scope="col" className="px-6 py-4">
												МОДЕЛЬ
											</th>
											<th scope="col" className="px-6 py-4">
												С имеи
											</th>
											<th scope="col" className="px-6 py-4">
												КОЛ-ВО
											</th>
											<th scope="col" className="px-6 py-4">
												Сумма
											</th>
											<th scope="col" className="px-6 py-4">
												ДАТА ЗАКУПКИ
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.map((v, i) => {
											return (
												<tr
													key={i}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{v.CardName || '-'}
													</td>
													<td className="px-6 py-4">{v.Dscription || '-'}</td>
													<td className="px-6 py-4">{v.U_IMEI_PURCH || '-'}</td>
													<td className="px-6 py-4">{+v.Quantity || '-'}</td>
													<td className="px-6 py-4">
														{(v.DocTotal && Number(v.DocTotal).toFixed(2)) || 0}{' '}
														USD
													</td>
													<td className="px-6 py-4">
														{moment(v.DocDate).format('DD-MM-YYYY')}
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>

							<div className="topCard">
								<div className="flex">
									<Button
										className={'btn'}
										onClick={oldData}
										btnStyle={{ marginRight: 10 }}
									>
										{'<'}
									</Button>
									<Button className={'btn'} onClick={newDatas}>
										{'>'}
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</Purchases>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default PurchasesPage
