import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../components/Layout'
import { get } from 'lodash'
import Button from '../../components/Button'
import { useSelector } from 'react-redux'
import customMainApi, { customFuncsApi } from '../../api'
import api from '../../api'
import { ErrorModal, SuccessModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import MainModal from '../../components/Modal/MainModal'
import CheckboxGroup from '../../components/GroupCheckboxes'

const InventoryTransfers = () => {
	const { getMe } = useSelector((state) => state.main)
	const initData = {
		ItemCode: '',
		ItemDescription: '',
		Quantity: 0,
		SerialNumbers: [],
	}
	const errorRef = useRef()
	const successModalRef = useRef()
	const imeiRef = useRef()

	const [body, setBody] = React.useState([{ ...initData }])
	const [fromWarehouse, setFromWarehouse] = React.useState(getMe.U_Warehouse)
	const [toWarehouse, setToWarehouse] = React.useState('')
	const [warehouses, setWarehouses] = React.useState([])
	const [btnLoading,SetBtnLoading]= React.useState(false)
	const [items, setItems] = React.useState([])
	const [imeis, setImeis] = React.useState([])
	const [itemLoading, setItemLoading] = React.useState(false)
	const [imeiLoading, setImeiLoading] = React.useState({
		loading: false,
		order: null,
	})
	const [warehouseLoading, setWarehouseLoading] = useState(false)
	const [selectedIemiOrder, setSelectedImeiOrder] = useState(null)

	const handleSelectSerialNumber = (imei) => {
		setBody((prev) => {
			const newBody = [...prev]
			newBody[selectedIemiOrder].SerialNumbers.push(imei)
			return newBody
		})
	}

	const handleRemoveSerialNumber = (imei) => {
		setBody((prev) => {
			const newBody = [...prev]
			newBody[selectedIemiOrder].SerialNumbers = newBody[
				selectedIemiOrder
			].SerialNumbers.filter((item) => item !== imei)
			return newBody
		})
	}

	const handleAddItem = () => {
		setBody((prev) => [...prev, { ...initData }])
	}

	const handleRemoveItem = (i) => {
		setBody((prev) => prev.filter((_, idx) => idx !== i))
	}

	useEffect(() => {
		getWarehouses()
		getItems(fromWarehouse)
	}, [fromWarehouse])

	const getImeis = (itemCode, whsCode, i) => {
		setImeiLoading({
			loading: true,
			order: i,
		})
		customFuncsApi
			.get(
				`xsSql/getItemSerialsByWhsCode?ItemCode='${itemCode}'&WhsCode='${whsCode}'`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=1000000',
					},
				},
			)
			.then((res) => {
				const resData = res.data.value
				setImeis((prevState) => {
					const newImeis = [...prevState]
					newImeis[i] = [...resData]
					return newImeis
				})
			})
			.catch((err) => {
				errorRef.current?.open(
					JSON.stringify(err.response.data?.message),
				)
			})
			.finally(() => {
				setImeiLoading({
					loading: false,
					order: null,
				})
			})
	}

	const getItems = (fromWarehouse) => {
		setItemLoading(true)
		customFuncsApi
			.get('xsSql/getItemsByWarehouse?whsCode=' + fromWarehouse, {
				headers: {
					Prefer: 'odata.maxpagesize=1000000',
				},
			})
			.then((res) => {
				setItems(res.data.value)
			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						JSON.stringify(err.response.data?.details?.message),
					)
			})
			.finally(() => {
				setItemLoading(false)
			})
	}

	const getWarehouses = () => {
		setWarehouseLoading(true)
		setBody([{ ...initData }])
		customMainApi
			.get('Warehouses?$select=WarehouseCode,WarehouseName')
			.then((res) => {
				console.log(res.data)
				setWarehouses(JSON.parse(res.data).value)
			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						JSON.stringify(err.response.data?.details?.message),
					)
			})
			.finally(() => {
				setWarehouseLoading(false)
			})
	}

	const submit = () => {
	SetBtnLoading(true)
		customMainApi
			.post('StockTransfers', {
				DocDate: new Date().toISOString(),
				FromWarehouse: fromWarehouse,
				ToWarehouse: toWarehouse,
				StockTransferLines: body.map((item) => {
					const { itemDescription, ...rest } = item
					const SerialNumbers = item.SerialNumbers.map((imei) => ({
						InternalSerialNumber: imei,
						// SystemSerialNumber:item.SysSerial
					}))
					return {
						...rest,
						// WarehouseCode: toWarehouse,
						SerialNumbers,
					}
				}),
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', '')
				)
				console.log(get(JSON.parse(err.response.data), 'error.message', ''))
			})
			SetBtnLoading(false)
	}

	const handleCheckboxGroupChange = (selectedValues) => {
		const newBody = [...body]
		newBody[selectedIemiOrder] = {
			...newBody[selectedIemiOrder],
			SerialNumbers: selectedValues,

		}
		setBody(newBody)
	}

	useEffect(() => {
		console.log(body)
	}, [body])

	return (
		<Layout>
			<div className={'py-10 px-32'}>
				<h1 className={'font-medium text-xl text-center mb-4'}>
					Перемещение запасов
				</h1>
				<div className={'flex justify-between'}>
					<div className={'flex flex-col min-w-[300px]'}>
						<label
							htmlFor="from-warehouse"
							className={'font-medium text-zinc-600 text-sm mb-1'}
						>
							Со склада
						</label>
						{get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? (
							<input
								value={
									warehouses.find(
										(item) => item.WarehouseCode === fromWarehouse,
									)?.WarehouseName
								}
								id={'from-warehouse'}
								className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] "
							/>
						) : (
							<div className={'flex items-center gap-1 w-full'}>
								<select
									id={'from-warehouse'}
									className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] w-full"
									onChange={(e) => setFromWarehouse(e.target.value)}
									value={fromWarehouse}
								>
									{/* <option value={null}>Выберите склад</option> */}
									{warehouses.map((v, i) => (
										<option key={i} value={v.WarehouseCode}>
											{v.WarehouseName}
										</option>
									))}
								</select>
								<ClipLoader size={15} loading={warehouseLoading} />
							</div>
						)}
					</div>
					<div className={'flex flex-col min-w-[300px]'}>
						<label
							htmlFor="to-warehouse"
							className={'font-medium text-zinc-600 text-sm mb-1'}
						>
							На склад
						</label>
						<div className={'flex items-center gap-1 w-full'}>
							<select
								id={'to-warehouse'}
								className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] w-full"
								onChange={(e) => setToWarehouse(e.target.value)}
								value={toWarehouse}
							>
								<option value={null}>Выберите склад</option>
								{warehouses
									.filter((v) => v.WarehouseCode !== fromWarehouse)
									.map((v, i) => (
										<option key={i} value={v.WarehouseCode}>
											{v.WarehouseName}
										</option>
									))}
							</select>
							<ClipLoader size={15} loading={warehouseLoading} />
						</div>
					</div>
				</div>
				<div className="relative max-h-[68vh] overflow-auto mb-16 mt-10">
					<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
						<thead className="sticky top-0 text-xs text-gray-700 uppercase bg-gray-50 ">
							<tr>
								<th scope="col" className="px-6 py-4">
									Товар
								</th>
								<th scope="col" className="px-6 py-4">
									Характеристика
								</th>
								<th scope="col" className="px-6 py-4 ">
									ИМЕИ
								</th>
								<th scope="col" className="px-6 py-4">
									Кол-во
								</th>
								<th scope="col" className="px-6 py-4"></th>
							</tr>
						</thead>
						<tbody>
							{body.map((v, i) => {
								return (
									<tr
										className="bg-white border-b hover:bg-gray-50 h-[130px]"
										key={i}
									>
										<td className="px-6 py-4">
											<div className={'flex items-center gap-1'}>
												<input
													list={'items'}
													className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] w-full"
													onChange={(e) => {
														if (
															items.find(
																(item) =>
																	item.ItemCode ===
																	e.target.value.split(' , ')[1],
															)
														) {
															setBody((prev) => {
																const newBody = [...prev]
																newBody[i].ItemCode =
																	e.target.value.split(' , ')[1]
																newBody[i].SerialNumbers = []
																return newBody
															})
															getImeis(
																e.target.value.split(' , ')[1],
																fromWarehouse,
																i,
															)
														}
													}}
												/>
												<datalist id={'items'}>
													{items.map((v, i) => (
														<option key={i}>
															{v.ItemName} , {v.ItemCode}
														</option>
													))}
												</datalist>
												<ClipLoader size={15} loading={itemLoading} />
											</div>
										</td>
										<td className="px-6 py-4">
											<input
												className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] w-full"
												onChange={(e) => {
													const newBody = [...body]
													newBody[i].ItemDescription = e.target.value
													setBody(newBody)
												}}
											/>
										</td>
										<td className="px-6 py-4 ">
											<div className={'flex items-center gap-1'}>
												<button
													onClick={() => {
														imeiRef.current?.open()
														setSelectedImeiOrder(i)
													}}
													className={`bg-[#FAFBFC] border ${
														body[i].SerialNumbers.length !== +body[i].Quantity
															? 'border-red-600'
															: 'border-[#DFE2E8]'
													} rounded-md p-3 text-sm text-[#1E1F1F] w-full focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:cursor-not-allowed`}
													disabled={
														(imeiLoading.order === i && imeiLoading.loading) ||
														!imeis[i]
													}
												>
													Выбирать IMEI
												</button>
												{i === imeiLoading.order && (
													<ClipLoader size={15} loading={imeiLoading.loading} />
												)}
											</div>
										</td>
										<td className="px-6 py-4">
											<input
												value={v.Quantity}
												className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] w-full"
												onChange={(e) => {
													const newBody = [...body]
													newBody[i].Quantity = e.target.value
													setBody(newBody)
												}}
											/>
										</td>
										<td className={'w-[100px]'}>
											{i === body.length - 1 ? (
												<button
													onClick={handleAddItem}
													className="px-4 py-2  bg-[#0A4D68] text-white rounded-md ml-2"
												>
													+
												</button>
											) : null}
											{body.length > 1 ? (
												<button
													onClick={() => handleRemoveItem(i)}
													className="px-4 py-2 bg-red-600 text-white rounded-md ml-2"
												>
													-
												</button>
											) : null}
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
				<div className={'flex justify-end'}>
					<Button
						disabled={
							!toWarehouse ||
							!!body.find((item) => item.ItemCode === '') ||
							!!body.find((item) => item.SerialNumbers.length === 0) ||
							!!body.find((item) => item.Quantity === 0)
						}
						loading={btnLoading}
						onClick={submit}
					>
						Создать
					</Button>
				</div>
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<SuccessModal
					getRef={(r) => {
						successModalRef.current = r
					}}
				/>
				<MainModal getRef={(r) => (imeiRef.current = r)}>
					<div
						className={
							'min-w-[400px] max-w-[400px] max-h-[400px] min-h-[300px] overflow-y-scroll'
						}
					>
						<CheckboxGroup
							items={imeis[selectedIemiOrder] || []}
							selectedItems={body[selectedIemiOrder]?.SerialNumbers || []}
							onChange={handleCheckboxGroupChange}
							handleSelectSerialNumber={handleSelectSerialNumber}
							handleRemoveSerialNumber={handleRemoveSerialNumber}
						/>
					</div>
				</MainModal>
			</div>
		</Layout>
	)
}

export default InventoryTransfers
