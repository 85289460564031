import React, { memo, useEffect, useRef, useState } from 'react'
import UpdateUserStyle from './UpdateUserStyle'
import Modal from 'react-modal'
import { get } from 'lodash'
import api from '../../../../api'
import { ErrorModal } from '../../../../components/Modal'
import { AiOutlineReload } from 'react-icons/ai'
import RegionsData from '../../../../components/Modal/CreateUser/Regions/regions.json'
import { useSelector } from 'react-redux'
import DistrictData from '../../../../components/Modal/CreateUser/Regions/districts.json'
import VillageData from '../../../../components/Modal/CreateUser/Regions/villages.json'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '50%',
	},
}

const UpdateUserModal = (props) => {
	const { getRef = () => {}, onUpdated = () => {}, onClose = () => {} } = props
	const { getMe } = useSelector((state) => state.main)
	const errorModalRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [passport, setPassport] = useState('')
	const [phone, setPhone] = useState('')
	const [workplace, setWorkplace] = useState('')
	const [male, setMale] = useState('')
	const [userName, setUserName] = useState('')
	const [userSureName, setUserSureName] = useState('')
	const [userFathersName, setUserFathersName] = useState('')
	const [disData, setDisData] = useState([])
	const [villData, setVillData] = useState([])
	const [region, setRegion] = useState('')
	const [district, setDistrict] = useState('')
	const [village, setVillage] = useState('')
	const [frozen, setFrozen] = useState('')
	const [passportAdress, setPassportAdress] = useState('')
	const [liveAdress, setLiveAdress] = useState('')
	const [score, setScore] = useState('')
	const [RowNum, setRowNum] = useState('')

	const [isLoading, setIsLoading] = useState(false)

	const getRegion = (text) => {
		let a = Number(text.split(' - ')[1])

		let changed_data = DistrictData.filter((v) => {
			return a === Number(get(v, 'region_id', 0))
		})
		setDisData(changed_data)
		setRegion(text)
		setDistrict('')
		setVillage('')
	}

	const getDistrict = (text) => {
		let a = Number(text.split(' - ')[1])

		let changed_data = VillageData.filter((v) => {
			return a === Number(get(v, 'district_id', 0))
		})
		setVillData(changed_data)
		setDistrict(text)
		setVillage('')
	}

	useEffect(() => {
		const ref = {
			open: (data) => {
				console.log(data)
				setIsOpenModal(true)
				setCardCode(
					get(data, 'CardCode', null) !== null ? get(data, 'CardCode', '') : '',
				)
				setUserName(
					get(data, 'CardName', null) !== null
						? get(data, 'CardName', '').split(' ')[1]
						: '',
				)
				setUserSureName(
					get(data, 'CardName', null) !== null
						? get(data, 'CardName', '').split(' ')[0]
						: '',
				)
				setUserFathersName(
					get(data, 'CardName', null) !== null
						? get(data, 'CardName', '').split(' ').slice(2).join(' ')
						: '',
				)
				setLiveAdress(
					get(data, 'Address', null) !== null ? get(data, 'Address', '') : '',
				)
				setPassport(
					get(data, 'U_PS', null) !== null ? get(data, 'U_PS', '') : '',
				)
				setPhone(
					get(data, 'Phone1', null) === null
						? get(data, 'U_Telephone', '')
						: get(data, 'Phone1', ''),
				)

				setWorkplace(
					get(data, 'U_workplace', null) !== null
						? get(data, 'U_workplace', '')
						: '',
				)
				setMale(
					get(data, 'U_Gender', null) === 'Male'
						? 'Male'
						: get(data, 'U_Gender', null) === 'Female'
							? 'Female'
							: '',
				)
				setFrozen(
					get(data, 'Frozen', null) !== null ? get(data, 'Frozen', '') : '',
				)
				setRowNum(get(data, 'BPAddresses', ''))

				setScore(get(data, 'U_Score', ''))
				console.log('Data = ')
				console.log(data)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const submit = () => {
		setIsLoading(true)
		api
			.patch(`BusinessPartners('${cardCode}')`, {
				SalesPersonCode: getMe.SalesPersonCode || -1,
				U_workplace: workplace,
				GroupCode: 100, // By default
				U_Telephone: `${phone}`,
				Currency: '##', // Bydefault
				U_Gender: male, // Erkak Ayol
				U_PS: passport, //Pasport
				U_Score: score !== null ? Number(score.split(' ')[0]) : '',
				Frozen: frozen,
				Valid: frozen === 'tNO' ? 'tYES' : 'tNO',
				BPAddresses: [
					{
						AddressName: 'Tashkent', //Auto generati qilib yuborasiz bir narsa
						Street: liveAdress, //LiveAdress
						City: village, //village
						County: district, //district
						Country: 'UZ', //By default
						StreetNo: region, //region
						RowNum:get(RowNum, 'RowNum', ''),
						BPCode: cardCode, //CardCode
					},
				],
			})
			.then(() => {
				setIsOpenModal(false)
			})
			.catch((err) => {
				console.log(err)
				errorModalRef.current?.open(JSON.parse(err.response.data).error.message)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<UpdateUserStyle>
				<div className="card">
					<h2 className="text-zinc-900 font-bold mb-4 text-[20px]">
						{'Обновите информацию'}
					</h2>
					<div className="flex flex-col gap-4">
						<div className="flex items-center gap-4">
							<input
								type="text"
								placeholder="Имя"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userName}
								onChange={(v) => setUserName(v.target.value)}
							/>
							<input
								type="text"
								placeholder="Фамилия"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userSureName}
								onChange={(v) => setUserSureName(v.target.value)}
							/>
							<input
								type="text"
								placeholder="Отчество"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userFathersName}
								onChange={(v) => setUserFathersName(v.target.value)}
							/>
						</div>

						<div className="flex items-center gap-4">
							<>
								<input
									type="text"
									placeholder="viloyat"
									list="region"
									value={region}
									onChange={(v) => getRegion(v.target.value)}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
									}
								/>

								<datalist id="region">
									{RegionsData.map((v, i) => {
										return <option key={i} value={`${v.name_uz} - ${v.id}`} />
									})}
								</datalist>
							</>
							<>
								<input
									type="text"
									placeholder="tuman"
									list="district"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
									}
									value={district}
									onChange={(v) => getDistrict(v.target.value)}
								/>

								<datalist id="district">
									{disData.map((v, i) => {
										return <option key={i} value={`${v.name_uz} - ${v.id}`} />
									})}
								</datalist>
							</>
							<>
								<input
									type="text"
									placeholder="Qishloq"
									list="village"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
									}
									value={village}
									onChange={(v) => setVillage(v.target.value)}
								/>

								<datalist id="village">
									{villData.map((v, i) => {
										return <option key={i} value={`${v.name_uz} - ${v.id}`} />
									})}
								</datalist>
							</>
						</div>
						<div className="flex items-center gap-4">
							<input
								type="text"
								placeholder="Passport manzili"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={passportAdress}
								onChange={(v) => setPassportAdress(v.target.value)}
							/>

							<input
								type="text"
								placeholder="Yashash manzili"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={liveAdress}
								onChange={(v) => setLiveAdress(v.target.value)}
							/>
						</div>
						<div className="flex items-center gap-4">
							<input
								type="text"
								placeholder="Код клиента"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={cardCode}
								onChange={(v) => setCardCode(v.target.value)}
							/>

							<input
								type="text"
								placeholder="Паспорт"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={passport}
								onChange={(v) => setPassport(v.target.value)}
							/>
						</div>
						<div className="flex items-center gap-4">
							<input
								type="number"
								placeholder="Asosiy telefon raqam"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={phone}
								onChange={(v) => setPhone(v.target.value)}
							/>

							<input
								type="text"
								placeholder="Рабочее место"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={workplace}
								onChange={(v) => setWorkplace(v.target.value)}
							/>
						</div>

						<div className="flex items-center gap-4">
							<div className="flex items-center ">
								<input
									type="text"
									placeholder="U_Score"
									list="U_Score"
									value={score}
									onChange={(v) => setScore(v.target.value)}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[270px] input1'
									}
								/>

								<datalist id="U_Score">
									<option key={1} value={`1 A`} />
									<option key={2} value={`2 B`} />
									<option key={3} value={`3 C`} />
									<option key={4} value={`4 D`} />
								</datalist>
							</div>
							<div className="flex items-center gap-4">
								<h1>Active :</h1>

								<input
									type="radio"
									id="yes"
									name="frozen"
									value={'Yes'}
									checked={frozen === 'tNO'}
									onChange={(v) =>
										setFrozen(v.target.value === 'Yes' ? 'tNO' : 'tYES')
									}
								/>

								<label htmlFor="yes">Yes</label>
								<div className="flex gap-1">
									<input
										type="radio"
										id="no"
										name="frozen"
										value="No"
										checked={frozen === 'tYES'}
										onChange={(v) =>
											setFrozen(v.target.value === 'No' ? 'tYES' : 'tNO')
										}
									/>
									<label htmlFor="no">No</label>
								</div>
							</div>
						</div>

						<div className="flex gap-4 justify-center my-4">
							<div className="flex gap-1">
								<input
									type="radio"
									id="male"
									name="fav_language"
									checked={male === 'Male'}
									value="Male"
									onChange={(v) => setMale(v.target.value)}
								/>

								<label htmlFor="male">Мужчина</label>
							</div>
							<div className="flex gap-1">
								<input
									type="radio"
									id="female"
									name="fav_language"
									value="Female"
									checked={male === 'Female'}
									onChange={(v) => setMale(v.target.value)}
								/>
								<label htmlFor="female">Женщина</label>
							</div>
						</div>
						<div className="centerCard">
							<button className="btnN" onClick={() => setIsOpenModal(false)}>
								{'Нет'}
							</button>
							<button
								className="btnY"
								// disabled={
								// 	cardCode.length <= 0 ||
								// 	userSureName.length <= 0 ||
								// 	userName.length <= 0 ||
								// 	userFathersName.length <= 0
								// }
								onClick={submit}
							>
								{!isLoading ? 'Да' : <AiOutlineReload size={'20'} />}
							</button>
						</div>
					</div>
				</div>
			</UpdateUserStyle>
			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
		</Modal>
	)
}

export default memo(UpdateUserModal)
