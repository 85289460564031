import React, { useEffect, useRef, useState } from 'react'
import CreateUserStyle from '../CreateUser/CreateUserStyle'
import Modal from 'react-modal'
import Button from '../../Button'

import { get } from 'lodash'
import { customFuncsApi } from '../../../api'
import { useSelector } from 'react-redux'
import customMainApi from '../../../api'
import { ErrorModal, SuccessModal } from '../index'
import moment from 'moment'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
	},
	overlay: {
		background: '#00000099',
	},
}

const ForgivvedPurchasesModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
	onUpdated = () => {},
}) => {
	const { getMe } = useSelector((state) => state.main)

	const errorModalRef = useRef()
	const successModalRef = useRef()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [item, setItem] = useState('')
	const [loading, setLoading] = useState(false)
	const [userFields, setUserFields] = useState([])
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
	const [debt, setDebt] = useState('')
	const [docEntry, setDocEntry] = useState('')

	useEffect(() => {
		const ref = {
			open: (data) => {
				console.log(data)
				setIsOpenModal(true)
				setCardCode(
					get(data, 'CardCode', null) !== null ? get(data, 'CardCode', '') : '',
				)
				setDocEntry(
					get(data, 'DocEntry', null) !== null ? get(data, 'DocEntry', '') : '',
				)
				setItem(
					get(data, 'Dscription', null) !== null
						? get(data, 'Dscription', '')
						: '',
				)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const submit = () => {
		console.log('a')
		setLoading(true)

		customFuncsApi
			.post(`closeInvoiceDebt`, {
				DocDate: date,
				invoiceDocEntry: docEntry,
				discount: debt,
				itemPrice: get(userFields, 'DocTotal', ''),
				SalesPersonCode: getMe.SalesPersonCode || -1,
				CardCode: cardCode,
			})
			.then(() => {
				setTimeout(() => {
					onConfirm()
					onUpdated()
					setIsOpenModal(false)
				}, 2000)
				successModalRef.current?.open('Пользователь успешно создан')
			})
			.catch((err) => {
				console.log(err)
				errorModalRef.current?.open(JSON.parse(err.response.data).error.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const getInvoices = () => {
		customMainApi
			.get(`Invoices(${docEntry})`, {
				params: {
					$select:
						'DocDate,DocTotal,CardName,CardCode,SalesPersonCode,PaidToDate,DocumentLines',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data))
				setDebt(
					get(JSON.parse(res.data), 'DocTotal', 0) -
						get(JSON.parse(res.data), 'PaidToDate', 0),
				)
				console.log(JSON.parse(res.data))
			})
	}

	useEffect(() => {
		if (docEntry) {
			getInvoices()
			const today = moment().format('YYYY-MM-DD')
			setDate(today)
		}
	}, [docEntry])

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<p className="title">
						{'Создать пользователя'}
						{'!'}
					</p>
					<div className="flex flex-col gap-10 mb-20">
						<div className="flexCard">
							<div className="flex flex-col items-start gap-3">
								<p>Дата</p>
								<input
									type="date"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md full '
									}
									value={date}
									onChange={(v) => setDate(v.target.value)}
								/>
							</div>

							<div className="flex flex-col items-start gap-3">
								<p>Поставщик</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md full'
									}
									value={get(userFields, 'CardName', '')}
									disabled={true}
								/>
							</div>

							<div className="flex flex-col items-start gap-3">
								<p>Цена товара</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(userFields, 'DocTotal', '')}
									disabled={true}
								/>
							</div>
						</div>

						<div className="flexCard">
							<div className="flex flex-col items-start gap-3 ">
								<p>Модель</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[200px] '
									}
									value={item}
									disabled={true}
								/>
							</div>

							<div className="flex flex-col items-start gap-3 ">
								<p>Сумма скидки</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[250px] '
									}
									value={debt}
									disabled={true}
								/>
							</div>
						</div>
					</div>

					<div className="centerCard">
						<button
							className="bg-red-500 text-white py-2 px-4 rounded-md btn"
							onClick={() => {
								onClose()
								setIsOpenModal(false)
							}}
						>
							{'Нет'}
						</button>
						<Button isLoading={loading} className={'btn'} onClick={submit}>
							{'Добавить'}
						</Button>
					</div>
				</div>
			</CreateUserStyle>
			<SuccessModal
				getRef={(r) => {
					successModalRef.current = r
				}}
			/>
			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
		</Modal>
	)
}

export default ForgivvedPurchasesModal
