import { createSlice } from '@reduxjs/toolkit'

export const recovery = createSlice({
	name: 'recovery',
	initialState: {
		name: '',
		imei: '',
		startDate: '',
		endDate: '',
		status: 'all',
		executer: undefined,
		currentPage: 0,
	},
	reducers: {
		setName: (state, action) => {
			state.name = action.payload
		},
		setImei: (state, action) => {
			state.imei = action.payload
		},
		setStartDate: (state, action) => {
			state.startDate = action.payload
		},
		setEndDate: (state, action) => {
			state.endDate = action.payload
		},
		setStatus: (state, action) => {
			state.status = action.payload
		},
		setExecuter: (state, action) => {
			state.executer = action.payload
		},
		setPage: (state, action) => {
			state.currentPage = action.payload
		},
	},
})
