import React, { useEffect, useRef, useState } from 'react'
import CreateUserStyle from './CreateUserStyle'
import Modal from 'react-modal'
import Button from '../../Button'
import RegionsData from './Regions/regions.json'
import DistrictData from './Regions/districts.json'
import VillageData from './Regions/villages.json'
import { get } from 'lodash'
import api from '../../../api'
import { useSelector } from 'react-redux'
import customMainApi from '../../../api'
import { ErrorModal } from '../index'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
	},
	overlay: {
		background: '#00000099',
	},
}

const ScoreCreateUser = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
	onUpdated = () => {},
}) => {
	const { getMe } = useSelector((state) => state.main)
	const errorModalRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [passport, setPassport] = useState('')
	const [phone, setPhone] = useState('')
	const [workplace, setWorkplace] = useState('')
	const [male, setMale] = useState('')
	const [userName, setUserName] = useState('')
	const [userSureName, setUserSureName] = useState('')
	const [userFathersName, setUserFathersName] = useState('')
	const [disData, setDisData] = useState([])
	const [villData, setVillData] = useState([])
	const [region, setRegion] = useState('')
	const [district, setDistrict] = useState('')
	const [village, setVillage] = useState('')
	const [passportAdress, setPassportAdress] = useState('')
	const [liveAdress, setLiveAdress] = useState('')
	const [loading, setLoading] = useState(false)
	const [katm, setKatm] = useState('')
	const [mib, setMib] = useState('')
	const [nasiya, setNasiya] = useState('')
	const [score, setScore] = useState('')
	const [userFields, setUserFields] = useState([])
	const [bornAdress, setBornAdress] = useState('')
	const [language, setLanguage] = useState('')

	useEffect(() => {
		const ref = {
			open: () => setIsOpenModal(true),
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const getRegion = (text) => {
		let a = Number(text.split(' - ')[1])

		let changed_data = DistrictData.filter((v) => {
			return a === Number(get(v, 'region_id', 0))
		})
		setDisData(changed_data)
		setRegion(text)
		setDistrict('')
		setVillage('')
	}

	const getDistrict = (text) => {
		let a = Number(text.split(' - ')[1])

		let changed_data = VillageData.filter((v) => {
			return a === Number(get(v, 'district_id', 0))
		})
		setVillData(changed_data)
		setDistrict(text)
		setVillage('')
	}

	const createUserF = () => {
		console.log('a')
		setLoading(true)
		api
			.patch(`BusinessPartners('${cardCode}')`, {
				SalesPersonCode: getMe.SalesPersonCode || -1,
				U_workplace: workplace,
				CardCode: cardCode, //CardCode
				CardName: ` ${userSureName} ${userName} ${userFathersName}`, //UserName, userSurname, userFathersName,
				CardType: 'cCustomer', // By default
				GroupCode: 100, // By default
				U_Telephone: `${phone}!`,
				Currency: '##', // Bydefault
				U_Gender: male, // Erkak Ayol
				U_PS: passport, //Pasport
				U_Score: Number(score.split(' ')[0]),
				U_KATM: katm, //Tavsirlab bergan yangi narsam
				U_MIB: mib, //Tavsirlab bergan yangi narsam
				U_Nasiya: nasiya, //Tavsirlab bergan yangi narsam
				Valid: 'tYES',
				U_Respawn: bornAdress,
				U_Language: language,
				BPAddresses: [
					{
						AddressName: `${liveAdress}`, //Auto generati qilib yuborasiz bir narsa
						Street: liveAdress, //LiveAdress
						City: village, //village
						County: district, //district
						Country: 'UZ', //By default
						StreetNo: region, //region
						BPCode: cardCode, //CardCode
					},
				],
			})
			.then(() => {
				onConfirm()
				onUpdated()
				setIsOpenModal(false)
			})
			.catch((err) => {
				console.log(err)
				errorModalRef.current?.open(JSON.parse(err.response.data).error.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OCRD'",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	useEffect(() => {
		userFieldsFn()
	}, [])

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<p className="title">
						{'Создать пользователя'}
						{'!'}
					</p>
					<div className="flex flex-col gap-4">
						<div className="flexCard">
							<input
								type="text"
								placeholder="Имя"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userName}
								onChange={(v) => setUserName(v.target.value)}
							/>
							<input
								type="text"
								placeholder="Фамилия"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userSureName}
								onChange={(v) => setUserSureName(v.target.value)}
							/>
							<input
								type="text"
								placeholder="Отчество"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userFathersName}
								onChange={(v) => setUserFathersName(v.target.value)}
							/>
						</div>
						<div className="flexCard">
							<>
								<input
									type="text"
									placeholder="viloyat"
									list="region"
									value={region}
									onChange={(v) => getRegion(v.target.value)}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
									}
								/>

								<datalist id="region">
									{RegionsData.map((v, i) => {
										return <option key={i} value={`${v.name_uz} - ${v.id}`} />
									})}
								</datalist>
							</>
							<>
								<input
									type="text"
									placeholder="tuman"
									list="district"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
									}
									value={district}
									onChange={(v) => getDistrict(v.target.value)}
								/>

								<datalist id="district">
									{disData.map((v, i) => {
										return <option key={i} value={`${v.name_uz} - ${v.id}`} />
									})}
								</datalist>
							</>
							<>
								<input
									type="text"
									placeholder="Qishloq"
									list="village"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
									}
									value={village}
									onChange={(v) => setVillage(v.target.value)}
								/>

								<datalist id="village">
									{villData.map((v, i) => {
										return <option key={i} value={`${v.name_uz} - ${v.id}`} />
									})}
								</datalist>
							</>
						</div>
						<div className="flexCard">
							<input
								type="text"
								placeholder="Passport manzili"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={passportAdress}
								onChange={(v) => setPassportAdress(v.target.value)}
							/>

							<input
								type="text"
								placeholder="Yashash manzili"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={liveAdress}
								onChange={(v) => setLiveAdress(v.target.value)}
							/>
						</div>
						<div className="flexCard">
							<input
								type="text"
								placeholder="Код клиента"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={cardCode}
								onChange={(v) => setCardCode(v.target.value)}
								disabled={true}
							/>

							<input
								type="text"
								placeholder="Паспорт"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={passport}
								onChange={(v) => setPassport(v.target.value)}
							/>
						</div>
						<div className="flexCard">
							<input
								type="text"
								placeholder="Tug’ilgan joy"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput '
								}
								value={bornAdress}
								onChange={(v) => setBornAdress(v.target.value)}
							/>
							<select
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={language}
								onChange={(e) => setLanguage(e.target.value)}
							>
								<option value="UZ">{'UZ'}</option>
								<option value="RU">{'RU'}</option>
								<option value="ENG">{'ENG'}</option>
							</select>
						</div>
						<div className="flexCard">
							{/* <div className="flex items-start "> */}
							<input
								type="text"
								placeholder="U_Score"
								list="U_Score"
								value={score}
								onChange={(v) => setScore(v.target.value)}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[200px] halfInput'
								}
							/>

							<datalist id="U_Score">
								<option key={1} value={`1 A`} />
								<option key={2} value={`2 B`} />
								<option key={3} value={`3 C`} />
								<option key={4} value={`4 D`} />
							</datalist>
							{/* </div> */}
							<label className={'w-full halfInput flex items-center gap-2'}>
								NASIYA :
								<select
									placeholder="Nasiya"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
									}
									value={nasiya}
									onChange={(v) => setNasiya(v.target.value)}
								>
									<option value={''}></option>
									{userFields
										?.find((item) => item.Name === 'Nasiya')
										?.ValidValuesMD.map((value) => (
											<option value={value.value}>{value.Description}</option>
										))}
								</select>
							</label>
						</div>
						<div className="flexCard">
							<label className={'w-full halfInput flex items-center gap-2'}>
								KATM
								<select
									placeholder="Katm"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
									}
									value={katm}
									onChange={(v) => setKatm(v.target.value)}
								>
									<option value={''}></option>
									{userFields
										?.find((item) => item.Name === 'KATM')
										?.ValidValuesMD.map((value) => (
											<option value={value.value}>{value.Description}</option>
										))}
								</select>
							</label>

							<label className={'w-full halfInput flex items-center gap-2'}>
								MIB
								<select
									placeholder="U_MIB"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
									}
									value={mib}
									onChange={(v) => setMib(v.target.value)}
								>
									<option value={''}></option>
									{userFields
										?.find((item) => item.Name === 'MIB')
										?.ValidValuesMD.map((value) => (
											<option value={value.value}>{value.Description}</option>
										))}
								</select>
							</label>
						</div>
						<div className="flexCard">
							<input
								type="number"
								placeholder="Телефон"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={phone}
								onChange={(v) => setPhone(v.target.value)}
							/>
							<input
								type="text"
								placeholder="Рабочее место"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md halfInput'
								}
								value={workplace}
								onChange={(v) => setWorkplace(v.target.value)}
							/>
						</div>
					</div>

					<div className="flex gap-4 justify-center my-4">
						<div className="flex gap-1">
							<input
								type="radio"
								id="male"
								name="fav_language"
								value="Male"
								onChange={(v) => setMale(v.target.value)}
							/>

							<label htmlFor="male">Мужчина</label>
						</div>
						<div className="flex gap-1">
							<input
								type="radio"
								id="female"
								name="fav_language"
								value="Female"
								onChange={(v) => setMale(v.target.value)}
							/>
							<label htmlFor="female">Женщина</label>
						</div>
					</div>

					<div className="centerCard">
						<button
							className="bg-red-500 text-white py-2 px-4 rounded-md btn"
							onClick={() => {
								onClose()
								setIsOpenModal(false)
							}}
						>
							{'Нет'}
						</button>
						<Button
							isLoading={loading}
							className={'btn'}
							disabled={
								userName.length <= 0 ||
								userSureName.length <= 0 ||
								userFathersName.length <= 0 ||
								region.length <= 0 ||
								district.length <= 0 ||
								village.length <= 0 ||
								passportAdress.length <= 0 ||
								liveAdress.length <= 0 ||
								cardCode.length <= 0 ||
								passport.length <= 0 ||
								phone.length <= 0 ||
								workplace.length <= 0 ||
								male.length <= 0
							}
							onClick={createUserF}
						>
							{'Да'}
						</Button>
					</div>
				</div>
			</CreateUserStyle>
			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
		</Modal>
	)
}

export default ScoreCreateUser
