import styled from 'styled-components'

const PaySupplierStyle = styled.div`
	min-height: 100vh;
	padding: 20px;
	padding-top: 30px;
	background-size: cover;
	background-position: center center;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: flex-start;

	.loader {
		margin-top: 150px;
		margin-left: 50%;
	}

	.container {
		flex: 1;
		box-shadow: 3px 3px 10px #cfcfcf;
		border-radius: 10px;
		background-color: #fffffff2;
		min-height: 92vh;

		.searchable-select {
			overflow: hidden;
			display: flex;
			flex-direction: column;
		}

		.searchable-select-list {
			max-height: 150px;
			overflow-y: scroll;
			border: 1px solid #ccc;
		}

		.searchable-select-item {
			padding: 5px;
			transition: all linear 0.2s;
			cursor: pointer;
		}

		.searchable-select-item:hover {
			background: #ebefef;
		}

		.form {
			padding: 40px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			align-items: start;
			gap: 20px;
		}

		input,
		select {
			font-size: 16px;
			padding: 4px 7px;
		}

		.searchableHead {
			display: flex;
			align-items: start;
			gap: 5px;
		}

		.selected-item {
			padding: 5px;
			background: #b9f6f6;
			border: 1px solid #53d0d0;
			border-radius: 5px;
		}

		.selected-items {
			left: 31%;
			position: absolute;
			align-items: start;
			flex-wrap: wrap;
			display: flex;
			max-width: 190px;
			background: aliceblue;
			padding: 10px;
			overflow-y: scroll;
		}

		.close-icon {
			color: #e81818;
		}
	}
`
export default PaySupplierStyle
