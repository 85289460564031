import styled from 'styled-components'
import colors from '../../../assets/style/colors'
import BackImage from '../../../assets/images/back.jpg'

const CashFlowReportStyle = styled.div`
	min-height: 100vh;
	padding: 20px;
	padding-top: 30px;
	// background-image: url(${BackImage});
	background-size: cover;
	background-position: center center;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	.loader {
		margin-top: 150px;
		margin-left: 50%;
	}
	.container {
		flex: 1;
		padding: 20px;
		box-shadow: 3px 3px 10px #cfcfcf;
		border-radius: 10px;
		background-color: #fffffff2;
		padding: 10px;
		min-height: 92vh;
		.topCard {
			display: flex;
			justify-content: space-between;
			padding: 0 20px;
			margin-top: 10px;
			.flex {
				display: flex;
				width: 80px;
				justify-content: space-between;
			}
			.btn {
				width: 35px;
				height: 35px;
				padding: 0;
				border-radius: 50px;
			}
		}
		.table {
			border-collapse: collapse;
			margin-top: 20px;
			background-color: #ffffff;
			width: 100%;
			td,
			th {
				border: 1px solid ${colors.gray};
				padding: 5px;
			}
			.row {
				background-color: #ffffff;
			}
			.row:hover {
				background-color: #a3fea6;
				cursor: pointer;
			}
		}
		.searchCard {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
			.input {
				border: 2px solid ${colors.gray};
				font-size: 14px;
				padding: 10px;
				border-radius: 5px;
				color: #434343;
				width: 280px;
				margin-bottom: 20px;
			}
		}
		.productTitle {
			font-size: 25px;
			font-weight: bold;
			color: #434343;
			padding: 0;
			margin: 0;
			text-align: center;
		}
	}
`
export default CashFlowReportStyle
