import React, { useEffect, useRef, useState } from 'react'
import ClientsStyle from './../Clients'
import Button from '../../../components/Button'
import api from '../../../api'
import { get } from 'lodash'
import Layout from '../../../components/Layout'
import { CreateUser, ErrorModal } from '../../../components/Modal'
import UpdateUser from './UpdateUser'
import ClipLoader from 'react-spinners/ClipLoader'

const ActiveClients = () => {
	const errorRef = useRef()
	const updateRef = useRef()
	const createUserRef = useRef()
	const succesCreateUserRef = useRef()

	const [isLoading, setIsLoading] = useState(false)
	const [clientName, setClientName] = useState('')
	const [clientPhone, setClientPhone] = useState('')
	const [clientCode, setClientCode] = useState('')
	const [page, setPage] = useState(0)
	const [data, setData] = useState({
		data: [],
		currentPage: 0, //20
	})
	const [createUserLoading, setCreateUserLoading] = useState(false)
	const search = () => {
		setIsLoading(true)
		let url =
			"BusinessPartners?$select=CardCode,CardName,Address,U_Telephone,U_Gender,U_Score,BPAddresses,Frozen,U_PS,U_workplace&$orderby=CardName asc&$filter=CardType eq 'cCustomer' and Frozen eq 'tNO'"
		if (
			clientCode.length >= 1 ||
			clientPhone.length >= 1 ||
			clientName.length >= 1
		)
			url += "&$filter=CardType eq 'cCustomer' and Frozen eq 'tNO'"
		if (clientName.length >= 1)
			url += ` and contains(CardName, '${clientName}')`
		if (clientPhone.length >= 1)
			url += ` and contains(U_Telephone, '${clientPhone}')`
		url += `&$skip=${page * 10}`
		api
			.get(url, { headers: { Prefer: 'odata.maxpagesize=10' } })
			.then((res) => {
				const resData = JSON.parse(res.data).value
				setData({
					data: [...resData],
				})
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
		setIsLoading(false)
	}

	useEffect(() => {
		search()
	}, [page])

	const viewItem = (v) => {
		updateRef.current?.open(v)
	}

	const newDatas = () => {
		if ((data.data.length = 10)) {
			setPage((prevState) => prevState + 1)
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const oldData = () => {
		if (page === 0) {
			alert('boshqa malumot yoq')
		} else {
			setPage((prevState) => prevState - 1)
		}
	}

	return (
		<Layout>
			<ClientsStyle>
				<div className="container">
					<p className="productTitle ">Активные клиенты</p>
					<div className="grid gap-4 grid-cols-1 my-8 mb-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
						<input
							type="text"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							placeholder="Имя Клиента"
							defaultValue={clientName}
							onChange={(v) => setClientName(v.target.value)}
						/>
						<input
							type="text"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							placeholder="Телефон клиента"
							defaultValue={clientPhone}
							onChange={(v) => setClientPhone(v.target.value)}
						/>

						<Button onClick={() => search(0, 0)} isLoading={isLoading}>
							Поиск
						</Button>
					</div>
					<div className={'flex justify-end'}>
						<button
							className="bg-[#0A4D68] text-white px-4 py-2 mb-5 rounded-md"
							onClick={() => createUserRef.current?.open()}
						>
							+
						</button>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="relative max-h-[68vh] overflow-auto mb-8 ">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="sticky top-0 text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												Код клиента
											</th>
											<th scope="col" className="px-6 py-4">
												Имя Клиента
											</th>
											<th scope="col" className="px-6 py-4">
												Телефон
											</th>
											<th scope="col" className="px-6 py-4">
												Паспорт
											</th>
											<th scope="col" className="px-6 py-4">
												Адрес
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.map((v, i) => {
											return (
												<tr
													className="bg-white border-b  hover:bg-gray-50 "
													key={i}
													onClick={() => viewItem(v)}
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{get(v, 'CardCode', '')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'CardName', '')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'U_Telephone', '')}
													</td>
													<td className="px-6 py-4">{get(v, 'U_PS', '')}</td>
													<td className="px-6 py-4">{get(v, 'Address', '')}</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
							<div className="topCard">
								<div className="flex items-center gap-2">
									<Button
										disabled={page === 0}
										className={'btn'}
										onClick={oldData}
									>
										{'<'}
									</Button>
									<p>{page + 1}</p>
									<Button
										disabled={data.data.length !== 10}
										className={'btn'}
										onClick={newDatas}
									>
										{'>'}
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</ClientsStyle>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
			<UpdateUser
				getRef={(ref) => (updateRef.current = ref)}
				onUpdated={() => search(data.currentPage, data.data)}
			/>
			<CreateUser
				isLoading={createUserLoading}
				getRef={(r) => (createUserRef.current = r)}
				onConfirm={() =>
					succesCreateUserRef.current?.open('Клиент успешно создан')
				}
			/>
		</Layout>
	)
}

export default ActiveClients
