import { combineReducers } from '@reduxjs/toolkit'
import { main } from '../slices/main'
import { recovery } from '../slices/recovery'
import { cashPayment } from '../slices/cash-payment'
import { instalmentPlan } from '../slices/instalment-plan'

const rootReducer = combineReducers({
	main: main.reducer,
	recovery: recovery.reducer,
	cashPayment: cashPayment.reducer,
	instalmentPlan: instalmentPlan.reducer,
})

// Named function for the default export
const rootReducerWrapper = (state, action) => rootReducer(state, action)

export default rootReducerWrapper
