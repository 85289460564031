import React, { memo, useEffect, useState } from 'react'
import MainModalStyle from './MainModalStyle'
import Modal from 'react-modal'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
	},
	overlay: {
		background: '#00000099',
	},
}

const MainModal = ({ children, getRef = () => {} }) => {
	const [isOpenModal, setIsOpenModal] = useState(false)
	useEffect(() => {
		const ref = {
			open: (soz) => {
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<MainModalStyle>{children}</MainModalStyle>
		</Modal>
	)
}

export default memo(MainModal)
