import styled from 'styled-components'

const MainModalStyle = styled.div`
	.modal {
		background-color: #000000;
		position: fixed;
		top: 0%;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`
export default MainModalStyle
