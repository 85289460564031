import React, { useRef } from 'react'
import { ErrorModal } from '../../components/Modal'
import { useLocation } from 'react-router-dom'
import Button from '../../components/Button'
import Layout from '../../components/Layout'
import DownloadStyle from './DownloadStyle'
import html2PDF from 'jspdf-html2canvas'
import { get } from 'lodash'
import moment from 'moment'

const Download = () => {
	const location = useLocation()
	const errorRef = useRef()
	const dataLocation = get(location, 'state.data', {})
	const data = [1, 1, 1]

	const download = () => {
		let element = document.getElementsByClassName('container')

		html2PDF(element, {
			margin: 10,
			output: `${get(location, 'state.user.Invoices.CardName', 'shartnoma')}.pdf`,
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		})
	}

	return (
		<Layout>
			<DownloadStyle>
				<div className="flexEnd">
					<Button
						onClick={download}
						btnStyle={{ marginBottom: 10, width: 225 }}
					>
						Шартномани юклаш
					</Button>
				</div>
				<div className="container">
					<div className="halfCardMini">
						<p className="topTitle">
							МУДДАТЛИ ТЎЛОВ АСОСИДА ОЛДИ-СОТДИ ШАРТНОМАСИ № _______{' '}
							{/*{get(dataLocation, "DocEntry", 0)}*/}
						</p>
						<div className="between">
							<p>Тошкент ш.</p>
							<p>
								{moment(new Date()).format('MM.DD.YYYY')}
								й.
							</p>
						</div>
						<p>
							«iSTYLE Plus» МЧЖ «Сотувчи» деб юритиладиган, низом асосида
							ҳаракат қилувчи раҳбари Шокиров Шохрух Зариф Ўғли, бир томондан,
							иккинчи томондан Ўзбекистон Республикаси фуқороси Ф.И.Ш
							_________________________________________
							{/*{get(location, "state.user.Invoices.CardName", "")}, кейинги*/}
							ўринларда «Сотиб олувчи» деб юритилади, қуйидагилар тўғрисида
							мазкур шартномани туздилар:
						</p>
						<>
							<p className="topTitle">1.ШАРТНОМА ПРЕДМЕТИ</p>
							<p>
								1.1. Сотувчи ўзига тегишли товар ва унга тегишли бўлган
								аксессуарларини Сотиб олувчига мулк қилиб топшириш, Сотиб олувчи
								эса ушбу товарни қабул қилиш ва тўловни шартномада келишилган
								тартибда ўз вақтида тўлаш мажбуриятини олади.
							</p>
							<p>1.2. Товар ҳақида маълумотлар:</p>
							<div className="containerTable">
								<table>
									<thead>
										<tr>
											<th>№</th>
											<th>
												Товарнинг
												<br />
												номи
											</th>
											<th>IMEI рақами</th>
											<th>Миқдори</th>
											<th>Бахоси</th>
											<th>Суммаси</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1</td>
											<td>
												{get(
													dataLocation,
													'DocumentLines[0].ItemDescription',
													'',
												)}
											</td>
											<td>
												{get(
													dataLocation,
													'DocumentLines[0].SerialNumbers[0].InternalSerialNumber',
													0,
												)}
											</td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
										<tr>
											<td colSpan={2}>
												<b>Итого:</b>
											</td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>
						</>
						<>
							<p className="topTitle">2.ШАРТНОМА ҚИЙМАТИ ВА ТЎЛОВ ТАРТИБИ</p>
							<p>
								2.1. Ушбу шартнома бўйича тўловлар қуйидаги тартибда амалга
								оширилади:
							</p>
							<p>
								2.2. Товарнинг умумий қиймати
								_________________________________________________________сўмни
								ташкил қилади.
							</p>
							<p>
								2.3. Сотиб олувчи шартномани имзоланган кунда товарнинг
								_________________ сўм бошланғич тўловини амалга оширади.
							</p>
							<p>
								2.4.Товарнинг қолган __________________________________ сўм
								қийматини ______ ой давомида __________________________________
								сўм дан тўлайди.
							</p>
							<p>
								2.5 Товар учун ойлик тўлов муддатлари товар сотиб олинган кунга
								белгиланади.
							</p>
							<p>
								2.6. Сотиб олувчи ойлик тўловларни шартноманинг 1-сонли
								иловасида кўрсатилган тартибда амалга оширади.
							</p>
							<p>
								2.7. Барча тўловлар сўмда Ўз.Рес Марказий банкининг тўлов
								кунидаги белгилаган курси бўйича қабул қилинади.
							</p>
						</>

						{/* thrid */}
						<>
							<p className="topTitle">
								3.ТОВАРЛАРНИ ТОПШИРИШ ВА ҚАБУЛ ҚИЛИШ ТАРТИБИ
							</p>
							<p>
								3.1. Сотувчи Сотиб олувчига у шартноманинг 2.3 бандида
								кўрсатилган бошланғич тўловни амалга оширгандан сўнг шартномада
								назарда тутилган товарни 1 иш куни давомида топшириши шарт.
							</p>
							<p>
								3.2. Сотувчи товарни ва унинг таркибига кирувчи ҳужжатларини
								шартнома шартларига жавоб берадиган миқдорда ва сифатда, ташиш
								жараёнида унинг бузилиши, синиши ёки йўқ булиши мумкинлигини
								истисно қиладиган даражадаги идишларда Сотиб олувчига топширади.
							</p>
							<p>
								3.3. Сотувчи товарнинг қути (каробка)сини Сотиб олувчи томонидан
								шартноманинг 2.2 бандида кўрсатилган умумий қиймати тўлиқ
								тўлангандан сўнг Сотиб олувчига топширади.
							</p>
							<p>
								3.4. Товарларни топшириш қуидаги манзил бўйича амалга оширилади:
								Тошкент ш, Юнусобод тумани, Марказ-6, 111 уй, 2-хонадон
							</p>
							<p>
								3.5. Сотиб олувчи шартномага мувофиқ етказиб берилган товарларни
								шартномага мувофиқлигини, яроқлилигини ва техник соз эканлигини
								текшириб уни қабул қилиб олади.
							</p>
						</>
						{/* fourth */}
						<p className="topTitle">4.ТОМОНЛАРНИНГ ҲУҚУҚ ВА МАЖБУРИЯТЛАРИ</p>
						<p>
							<b>4.1. Сотувчининг ҳуқуқлари:</b>
						</p>
						<p>
							-шартнома шартларига мувофиқ сотилган товар учун тўловларни ўз
							вақтида талаб қилиш.
							<br />
							-Сотиб олувчига тўлов ҳақида эслатиш учун смс ҳабарномалар юбориб
							туриш.
						</p>
						<p>
							<b>4.2. Сотувчининг мажбуриятлари:</b>
						</p>
						<p>
							- товарни сотиб олувчига мулк қилиб топшириш;
							<br /> - шартномада назарда тутилган товарни топшириш;
							<br /> - шартномада кўрсатилган миқдордаги товарни топшириш;
							<br /> - шартномада кўрсатилган сифатдаги товарни топшириш.
						</p>
						<p>
							<b>4.3. Сотиб олувчининг ҳуқуқлари:</b>
						</p>
						<p>
							- сотиб олаётган товарни ўзига мулк қилиб топширишни;
							<br /> - шартномада кўрсатилган миқдордаги товарни топширишни;
							<br /> - шартномада кўрсатилган сифатдаги товарни топширишни талаб
							қилиш.
						</p>
					</div>
				</div>

				{/* secend big Card */}
				<div className="container">
					<div className="halfCardMini">
						<p>
							<b>4.4. Сотиб олувчининг мажбуриятлари:</b>
						</p>
						<p>
							- тўловларни шартномада кўрсатилган тартибда ўз вақтида амалга
							ошириш.
							<br /> - шартноманинг 2.3 бандида кўрсатилган бошланғич тўловни
							амалга оширгандан сўнг товарни ўз вақтида қабул қилиш.
							<br /> - шартнома шартларига тўлиқ риоя этиш;
							<br /> - Сотувчига етказилган зарарни қоплаш.
						</p>
						{/* fifth */}
						<p className="topTitle">5. КАФОЛАТ</p>
						<p>
							5.1. iSTYLE PLUS техника дўконидаги маҳсулотлар кафолат билан
							сотилади ва мижозларга ўз вақтида кафолат ва техник хизмат
							кўрсатилади.
						</p>
						<p>
							5.2. Шартнома бўйича сотилган товарларнинг кафолати деганда
							Тошкент шахрида ҳизмат кўрсатаётган расмий сервис марказларида
							амалга ошириладиган техник ва дастурий таъмирлашлар тушунилади.
						</p>
						<p>
							5.3. Кафолат шартлари:
							<br /> - янги қурилмалар учун кафолат муддати 3 (уч) ой.
							<br /> - фойдаланилган қурилмалар учун кафолат муддати 1 (бир) ой.
							<br /> - кафолат ишлаб чиқарувчи томонидан юзага келган
							камчиликларни қоплайди.
							<br /> - агар янги қурилмаларда 3 кун ичида ишлаб чиқаришдаги
							нуқсон аниқланса, қурилма янгисига алмаштириб берилади.
							<br /> - кафолат муддати товар Сотиб олувчига топширилган кундан
							бошлаб ҳисобланади.
							<br /> - кафолат механик шикастланишларни қамраб олмайди:
							(корпусдаги нуқсонлар, синган дисплей, эгилган корпус, қурилма
							ичига кирган сув ва намликлар.)
							<br /> - Сотиб олувчи товарни бошқа мақсадларда ишлатса ёки ишлаб
							чиқарувчи кўрсатмаларидан фарқли равшда фойдаланса, Сотувчи Сотиб
							олувчи олдида ҳеч қандай кафолат мажбуриятларини ўз зиммасига
							олмайди.
						</p>
						{/* sixth */}
						<p className="topTitle">6. ТОМОНЛАРНИНГ ЖАВОБГАРЛИГИ</p>
						<p>
							6.1. Сотиб олувчи сотиб олган қурилмани унинг қути (каробка)си ва
							ҳимоя тизими (Apple қурилмаларига iCloud ҳимоя тизимини ўрнатиш)
							орқали қарзи эвазига Сотувчига гаров сифатида қўяди.
						</p>
						<p>
							6.2. Сотувчи томонидан сотилган Apple қурилмаларига ўрнатиладиган
							iCloud ҳимоя тизими виждонсиз харидорларни аниқлаш ва уларни
							масофадан блоклашни мақсад қилади.
						</p>
						<p>
							6.3. Сотувчи томонидан Apple қурилмаларига ўрнатиладиган iCloud
							ҳимоя тизими Сотувчига Сотиб олувчиниг шахсий маълумотларига кириш
							ҳуқуқини бермайди ва Сотувчи Сотиб олувчининг шахсий маълумотлари
							дахилсизлигини мутлоқ кафолатлайди.
						</p>
						<p>
							6.4. Сотиб олувчи шартнома бўйича тўловларни ўз вақтида амалга
							оширмаган холларда Сотувчи қуйдаги чораларни кўриши мумкин:
						</p>
						<p>
							6.5 Сотувчи шартнома бўйича муддати ўтган тўловларни Сотиб
							олувчининг барча мавжуд банк карталари, шу жумладан хорижий валюта
							карталаридан (Visa, UnionPay, MIR, Master Card) пул маблағларни
							шартномада юзага келган қарздорлиги учун акцептсиз ечиб олади. Шу
							муносабат билан, Сотиб олувчи бу шартномани имзолаши орқали тўлов
							кечиктирилган тақдирда Сотиб олувчи қарорисиз банк картасидан
							(карта-ҳисоб варағидан) пул маблағларини Сотувчи олдидаги қарзини
							қоплаш учун ечиб олишга тўлиқ ва шартиз розилик беради.
						</p>
						<p>
							6.6. Банк картасидан (карта-ҳисоб варағидан) пул маблағларининг
							ечиб олинганлиги ҳакида Сотиб олувчига (унга СМС-хабарнома хизмати
							уланган бўлиши шарти билан) СМС-хабарнома орқали хабар берилади.
						</p>
						<p>
							6.7. Сотиб олувчи ойлик тўловларни Сотувчини ёзма равишда
							огохлантирмасдан бесабаб 10 кунга кечиктирса, қурилмага уни
							блокланиши ҳақида хабарнома юборилади.
						</p>
						<p>
							6.8. Хабарнома юборилгандан сўнг, Сотиб олувчи 10 кун ичида
							тўловни амалга оширмаса, қўрилма масофадан блокланади.
						</p>
						<p>
							6.9. Блокланган қурилмалардаги маълумотлар сақланиб қолишлигини
							Сотувчи кафолатламайди.
						</p>
						<p>
							6.10. Шартнома бўйича тўловлар тўлиқ амалга оширилгандан сўнг,
							қўрилма ҳимоя тизимидан чиқарилади ва қурилманинг қути (каробка)си
							Сотиб олувчига қайтариб берилади.
						</p>
						<p>
							6.11. Сотиб олувчи шартнома бўйича мажбуриятларини бажара
							олмаганлиги сабабли товарни қайтариб берган тақдирда, Сотувчи
							Сотиб олувчи томонидан тўланган маблағларни ва товарнинг
							қайтарилган кунидаги эскирганлигини ҳисобга олган холда товарнинг
							қийматини қайта ҳисоб-китоб қилади. Бунда тегишли томон нархдаги
							фарқларни қоплайди.
						</p>
						<p>
							6.12. Юқоридаги чоралар Сотувчининг ихтиёрига кўра амалга
							оширилади.
						</p>
					</div>
				</div>

				{/* secend big Card */}
				<div className="container">
					<div className="halfCardMini">
						<p className="topTitle">7.ЯКУНИЙ ШАРТЛАР</p>
						<p>7.1. Ушбу шартнома имзоланган пайтдан бошлаб кучга киради. </p>
						<p>
							7.2. Ушбу шартнома Сотиб олувчи ўз мажбуриятларини тўлиқ
							бажаргунига қадар амал қилади.
						</p>
						<p>
							7.3. Томонлар ўз манзил ва реквизитларини ўзгартисалар 3 иш куни
							ичида бир-бирларини ёзма равишда хабардор қилишлари шарт.
						</p>
						<div>
							<div className="allCard">
								<p className="topTitle2">
									8.ТАРАФЛАРНИНГ ЮРИДИК МАНЗИЛЛАРИ ВА РЕКВИЗИТЛАРИ
								</p>
							</div>
							<div className="container2">
								<div className="halfCard">
									<div className="halfCardMini">
										<p className="centerTitle">Сотувчи: «iSTYLE PLUS» МЧЖ</p>
										<p>
											<b>Манзил:</b> Тошкент ш, Юнусобод тумани, Марказ-6, 111
											уй, 2-хонадон.
										</p>
										<p>
											<b>Банк:</b> «Ипотека-банк» АТИБ Тошкент шахар филиали.
										</p>
										<p>
											<b>МФО:</b> 00419
										</p>
										<p>
											<b>X/p:</b> 20208000405725426001
										</p>
										<p>
											<b>ИНН:</b> 310970893
										</p>

										<p>
											<b>Директор</b>
										</p>
										<p>
											<b>Шокиров Ш.З</b> __________________
										</p>
									</div>
								</div>

								{/* right */}
								<div className="halfCard">
									<div className="halfCardMini">
										<p className="centerTitle">Сотиб олувчи</p>
										<p>
											<b>Ф.И.Ш:</b> _________________________________________
										</p>
										<p>
											<b>Пасспорт id / сериаси ва рақами:</b>{' '}
											____________________
										</p>
										<p>
											<b>Манзил:</b> _______________________________________
											<br />
											<br /> _______________________________________________
										</p>
										<p>
											<b>Телефон рақами:</b> _______________________________
										</p>
										<p>
											<b>Имзо:</b> __________________
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="containerTable">
							<p>
								Муддатли тўлов асосида олди-сотди <br /> шартномасига 1-сонли
								илова <br />
								"____"____________________2024й.
							</p>
							<div className="containerTable">
								<p className="tableTitle">ТЎЛОВЛАРНИ АМАЛГА ОШИРИШ ЖАДВАЛИ</p>

								<table>
									<thead>
										<tr>
											<th>№</th>
											<th>Тўлов муддати</th>
											<th>Ойлик тўлов миқдори</th>
											<th>Изоҳлар учун</th>
										</tr>
									</thead>
									<tbody>
										{get(location, 'state.tableData', []).map((v, i) => {
											return (
												<tr key={i}>
													<td>{i + 1}</td>
													<td>
														{moment(
															get(
																dataLocation,
																`DocumentInstallments[${i}].DueDate`,
																'',
															),
														).format('MM.DD.YYYY')}
													</td>
													<td></td>
													<td></td>
												</tr>
											)
										})}

										<tr>
											<td colSpan={2}>
												<b>Жами:</b>
											</td>
											<td></td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="container2">
								<div className="halfCard">
									<div className="halfCardMini">
										<b>
											<p>Сотувчи «iSTYLE PLUS» МЧЖ</p>
											<p>Директор</p>
										</b>
										<p>Шокиров Ш.З ___________________</p>
									</div>
								</div>
								<div className="halfCard">
									<div className="halfCardMini">
										<b>Сотиб олувчи:</b>
										<p>Ф.И.Ш _________________________________________</p>
										<br />
										<p>Имзо: _________________________________________</p>
										<br />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</DownloadStyle>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
		</Layout>
	)
}

export default Download
