import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
	SuccessModal,
	ErrorModal,
	PaymentModal,
	AcceptCancelModal,
} from '../../components/Modal'
import numberWithSpaces from '../../helpers/numberWithSpaces'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import NoDollar from '../CreateProducts/NoDollar'
import Layout from '../../components/Layout'
import Button from '../../components/Button'
import ViewItemStyle from './ViewItemStyle'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import api, { customFuncsApi } from '../../api'
import { roundCurrency } from '../../utils'
import moment from 'moment'
import html2PDF from 'jspdf-html2canvas'
import customMainApi from '../../api/index'

const ViewItem = () => {
	const navigate = useNavigate()
	const location = useLocation()

	const successModalRef = useRef()
	const errorRef = useRef()
	const noDollarRef = useRef()
	const paymentRef = useRef()
	const acceptRef = useRef()

	const { getMe } = useSelector((state) => state.main)
	// const data = get(location, 'state', {})
	// const DocEntry = get(location, 'state.DocEntry', 0)
    const query = new URLSearchParams(location.search);
    const data = JSON.parse(query.get('data')) || {};
    const DocEntry = parseInt(query.get('DocEntry'), 10) || 0;


	const getNoDollarRef = useCallback((ref) => {
		noDollarRef.current = ref
	}, [])

	const [tableData, setTableData] = useState([])
	const [tableData2, setTableData2] = useState([])
	const [deviceInfoData, setDeviceInfoData] = useState([])
	const [downloadData, setDownloadData] = useState({ state: location.state })
	const [info1, setInfo1] = useState('')
	const [tableInvoicePayments, setTableInvoicePayments] = useState([])
	const [info2, setInfo2] = useState('')
	const [paymentSum, setPaymentSum] = useState(0)
	const [U_DaftarPage, setU_DaftarPage] = useState('')

	const [sumPay, setSumPay] = useState(0)
	const [dollar, setDollar] = useState(1)
	const [dollarLoading, setDollarLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isLoading2, setIsLoading2] = useState(false)
	const [isLoading3, setIsLoading3] = useState(false)

	useEffect(() => {
		getInformationOfItem()
		getInfoOFDevice()
		getInvoicePayments()
	}, [])

	const getInformationOfItem = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInstallments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=50',
				},
			})
			.then((res) => {
				const resData = res.data.value || []
				setTableData2(resData)
				setTableData(resData)
				setIsLoading(false)
				let a = JSON.parse(JSON.stringify(resData))
				let allSum = 0
				let paymentSum = 0
				for (let i = 0; i < a.length; i++) {
					allSum = allSum + a[i].PaidToDate
					paymentSum = paymentSum + a[i].InsTotal
				}
				setSumPay(allSum)
				setPaymentSum(paymentSum)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getUSD = () => {
		setDollarLoading(true)
		api
			.post(`SBOBobService_GetCurrencyRate`, {
				Currency: 'UZS',
				Date: moment(),
			})
			.then((res) => {
				setDollar(JSON.parse(res.data))
			})
			.catch((err) => {
				setDollar('error')
			})
			.finally(() => setDollarLoading(false))
	}

	useEffect(() => {
		getUSD()
	}, [])

	const getInfoOFDevice = () => {
		setIsLoading2(true)

		api
			.get(`Invoices(${DocEntry})`)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'DocumentLines[0]', [])
				setU_DaftarPage(get(JSON.parse(res.data), 'u_DaftarPage', null))

				getItems(get(resData, 'ItemCode', ''))
				setU_DaftarPage(JSON.parse(res.data))
				setDeviceInfoData(resData)
				setIsLoading2(false)
				setDownloadData(JSON.parse(res.data))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getItems = (code) => {
		api
			.get(`Items('${code}')`)
			.then((res) => {
				setInfo1(
					get(JSON.parse(res.data), 'U_Condition', 'Состояние не найден'),
				)
				setInfo2(get(JSON.parse(res.data), 'U_Color', 'Цвет не найден'))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const createPayment = ({
		DocDate,
		CurrencyRate,
		AmountUsd,
		AmountUzs,
		AmountPayme,
		NumberCard,
		Terminal,
	}) => {
		setIsLoading(true)
		const payments = []
		if (AmountUsd) {
			payments.push({
				usdSum: AmountUsd,
				account: getMe.U_CashAccount,
				currency: 'USD',
				uzsSum: null,
			})
		}
		if (AmountUzs) {
			payments.push({
				usdSum: AmountUzs / CurrencyRate,
				account: getMe.U_CashUzsAccount,
				currency: 'UZS',
				uzsSum: AmountUzs,
			})
		}
		if (AmountPayme) {
			payments.push({
				usdSum: AmountPayme / CurrencyRate,
				account: NumberCard || getMe.U_CardAccount,
				currency: 'UZS',
				uzsSum: AmountPayme,
			})
		}
		if (Terminal) {
			payments.push({
				usdSum: Terminal / CurrencyRate,
				account: getMe.U_TerminalAccount,
				currency: 'UZS',
				uzsSum: Terminal,
			})
		}
		customFuncsApi
			.post('addIncomingPayments', {
				DocDate,
				DocEntry: DocEntry,
				CardCode: get(data, 'CardCode', null),
				DocRate: CurrencyRate,
				payments: payments,
			})
			.then((err) => {
				paymentRef.current?.close()
				successModalRef.current?.open('Платеж успешно произведен!')
				getInformationOfItem()
			})
			.catch((err) => {
				paymentRef.current?.close()
				errorRef.current?.open(JSON.stringify(err.response.data))
			})
			.finally(() => {
				setIsLoading(false)
			})
	}
	const getInvoicePayments = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInvoiceIncomingPayments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setTableInvoicePayments(res.data.value)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}
	console.log(get(getMe, 'Department2.Name', '') === 'Boshliq')
	console.log(getMe)
	const cancel = () => {
		navigate(-1)
	}

	const handleClose = (docEntry) => {
		setIsLoading3(true)
		customFuncsApi
			.post(`cancelInvoiceWithPayments/${docEntry}`)
			.then((err) => {
				successModalRef.current?.open('Продажа успешно отменено!')
				setTimeout(() => {
					navigate(-1)
				}, 2000)
			})
			.catch((err) => {
				// errorRef.current?.open(JSON.stringify(err.response.data))
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setIsLoading3(false)
			})
	}

	const downloadCheck = (id, data) => {
		let element = document.getElementById('check' + id)

		html2PDF(element, {
			margin: 10,
			filename: 'myfile.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 5, logging: true, dpi: 150, letterRendering: true },
			jsPDF: {
				unit: 'mm',
				format: 'a4',
				orientation: 'portrait',
				fontSize: 14,
			},
		})
	}

	return (
		<Layout>
			<ViewItemStyle>
				<div className="container">
					<div className="grid grid-cols-1 w-full xl:grid-cols-3 gap-6">
						<div className="grid col-span-2 sm:grid-cols-2 gap-6 mb-8 h-fit">
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>ФИО</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(data, 'CardName', 'Покупатель')}`}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Продукт
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(data, 'Dscription', '')}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Кому принадлежит телефон
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(data, 'U_Owner', '-')}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Гарант
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(data, 'U_Guarantor', '-')}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>IMEI</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(
										deviceInfoData,
										'SerialNumbers[0].InternalSerialNumber',
										0,
									)}`}
									disabled={true}
								/>
							</div>



							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>Цвет</p>
								<input
									// type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={info2}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Состояние
								</p>
								<input
									// type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={info1}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Текущая цена
								</p>
								<input
									type="number"
									placeholder="Текущая цена"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(
										deviceInfoData,
										'GrossBuyPrice',
										'Текущая цена не найден',
									)}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>Цена</p>
								<input
									type="number"
									placeholder="Цена"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(deviceInfoData, 'GrossTotal', 'Цена не найден')}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Комментария
								</p>
								<textarea
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									rows="1"
									disabled={true}
									value={`${get(data, 'Comments', '')}`}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Номер страницы
								</p>
								<input
									// placeholder="Номер страницы"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(U_DaftarPage, 'U_DaftarPage', 'null')}`}
									disabled={true}
								/>
							</div>
						</div>
					</div>
					<div className="flex flex-col items-end">
						<div className="flex flex-col sm:flex-row gap-2 sm:gap-6">
							<p>
								<span className="font-bold text-zinc-900">Курс:</span>{' '}
								{dollar === 'error'
									? 'Курс не загружен. Пожалуйста, введите курс'
									: numberWithSpaces(dollar) + ' ' + 'UZS'}
							</p>
							<p>
								<span className="font-bold text-zinc-900">Долг:</span>{' '}
								<span className="debt">
									-{(paymentSum - sumPay).toFixed(2)}
								</span>{' '}
								USD
							</p>
						</div>
						<div className="flex items-center gap-5">
							{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ||
							get(getMe, 'Department2.Name', '') === 'Sotuv' ? (
								<div className="center py-5">
									<Button
										btnStyle={{ backgroundColor: 'red' }}
										onClick={() => acceptRef.current?.open()}
										isLoading={isLoading3}
										hoverBtnStyle={{ backgroundColor: 'red' }}
									>
										Отмена продажи
									</Button>
								</div>
							) : (
								''
							)}

							<div className="center py-5">
								<Button
									btnStyle={{ backgroundColor: '#243AB5' }}
									onClick={() => paymentRef.current?.open()}
									isLoading={isLoading || isLoading2 || dollarLoading}
									hoverBtnStyle={{ backgroundColor: '#243AB5' }}
								>
									Оплатить
								</Button>
							</div>
						</div>

						<div className="flex gap-10">
							<div className="overflow-y-auto ">
								<h1 className={'font-bold mb-3'}>Факт платежа</h1>
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												N
											</th>
											<th scope="col" className="px-6 py-4">
												Дата
											</th>
											{/*<th scope="col" className="px-6 py-4">*/}
											{/*	Оплата*/}
											{/*</th>*/}
											<th scope="col" className="px-6 py-4">
												Оплачено
											</th>
											<th scope="col" className="px-6 py-4">
												Номер счета
											</th>
										</tr>
									</thead>
									<tbody>
										{tableInvoicePayments.map((v, i) => {
											return (
												<tr
													className="bg-white border-b  hover:bg-gray-50 "
													key={i}
												>
													<td className="px-8 py-6 font-medium text-gray-900 ">
														{v.InstId - 1}
													</td>
													<td className="px-6 py-4 whitespace-nowrap">
														{moment(v.DocDate).format('DD-MM-YYYY')}
													</td>
													<td className="px-6 py-4">
														{Number(v.CashSum).toFixed(2)}
													</td>
													<td className="px-6 py-4 ">{Number(v.CashAcct)}</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
							<div className="overflow-y-auto mt-9">
								<table className="w-full text-sm text-left rtl:text-right text-black">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												N
											</th>
											<th scope="col" className="px-6 py-4">
												Дата
											</th>
											<th scope="col" className="px-6 py-4">
												Оплата
											</th>
											<th scope="col" className="px-6 py-4">
												Оплачено
											</th>
											<th scope="col" className="px-6 py-4">
												Действие
											</th>
										</tr>
									</thead>
									<tbody>
										{tableData.map((v, i) => {
											let a = v.DueDate.split('')
											a.splice(4, 0, '-')
											a.splice(7, 0, '-')
											a.join()
											return (
												<tr
													className="bg-white border-b  hover:bg-gray-50 "
													key={i}
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{i}
													</td>
													<td className="px-6 py-4 whitespace-nowrap">{a}</td>
													<td className="px-6 py-4">
														{Number(v.InsTotal).toFixed(2)}
													</td>
													<td className="px-6 py-4">
														{roundCurrency(v.PaidToDate)}
													</td>
													<td className="px-6 py-4">
														<button
															className={
																'whitespace-nowrap bg-[#0A4D68] text-white p-2 rounded-md disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 hover:bg-[#0A4D68] hover:text-white'
															}
															onClick={() => downloadCheck(i, v)}
															disabled={
																isLoading || isLoading2 || dollarLoading
															}
														>
															скачать
														</button>
													</td>
													<div
														id={'check' + i}
														className={
															'absolute -top-[100%] -right-[100%] w-[210mm]'
														}
													>
														<div className="w-[600px] mx-auto mt-10 p-6 bg-white border border-gray-300 rounded-md shadow-md">
															<h2 className="text-center text-[18px] font-semibold mb-4">
																Квитанция платежа кредита
																<br />
																Кредит тўлов бланкаси
															</h2>
															<div className={'flex justify-between'}>
																<div className="flex flex-col whitespace-nowrap justify-between mb-4">
																	<div className="font-bold text-center">
																		{roundCurrency(v.PaidToDate * dollar)}
																	</div>
																	<div className={'overline mt-2'}>
																		Сумма платежа/Тўлов суммаси:
																	</div>
																</div>
																<div className="flex justify-between mb-4 items-end">
																	<div>
																		<div>Дата платежа:</div>
																		<div>Тўлов санаси:</div>
																	</div>
																	<div className="font-bold">
																		{moment(v.DueDate).format('DD/MM/YYYY')}
																	</div>
																</div>
															</div>
															<div className="flex flex-col justify-between mb-4">
																<div className="font-bold text-center mb-4"></div>
																<div
																	className={
																		'border-t-[1px] border-black text-center mt-2'
																	}
																>
																	Фамилия И.О. плательщика/Тўловчи Фамилия И.Ш.:
																</div>
															</div>
															<div className="flex mb-4 items-end">
																<div>
																	<div>Тел. номер плательщика:</div>
																	<div>Тўловчи тел. рақами:</div>
																</div>
																<div className="font-bold border-b-[1px] pb-2 flex-1 border-black text-center">
																	80-354-88-63
																</div>
															</div>
															<div className="flex items-end mb-4">
																<div>
																	<div>Наименование платежа:</div>
																	<div>Тўлов номи:</div>
																</div>
																<div className="font-bold border-b-[1px] pb-2 flex-1 border-black text-center">
																	{DocEntry}
																</div>
															</div>
															<div
																className={
																	'flex justify-between item-start gap-4'
																}
															>
																<div className="flex items-end flex-1">
																	<div>
																		<div>Получатель платежа:</div>
																		<div>Тўловни қабул қилувчи:</div>
																	</div>
																	<div className="font-bold border-b-[1px] pb-2 flex-1 border-black">
																		{get(v, 'firstName', '')}{' '}
																		{get(v, 'lastName', '')}
																	</div>
																</div>

																<div className="flex justify-between items-end mt-6 min-w-[150px]">
																	<div>
																		<div className="font-semibold">
																			Подпись:
																		</div>
																		<div className="font-semibold">Имзо:</div>
																	</div>
																	<div
																		className={
																			'font-bold border-b-[1px] flex-1 border-black '
																		}
																	></div>
																</div>
															</div>
														</div>
													</div>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div className="bottomCard justify-end mt-8">
						<Button btnStyle={{ backgroundColor: 'red' }} onClick={cancel}>
							Назад
						</Button>

						<Button
							btnStyle={{ marginLeft: 20, width: 200 }}
							onClick={() => {
								if (window.location.hostname.toLowerCase().includes('istyle')) {
									navigate('/ru_download', {
										state: { data: downloadData, user: data, tableData },
									})
								} else {
									navigate('/download', {
										state: { data: downloadData },
									})
								}
							}}
						>
							Загрузить договор(Ру)
						</Button>
						{window.location.hostname.toLowerCase().includes('istyle') && (
							<Button
								btnStyle={{ marginLeft: 20, width: 225 }}
								onClick={() =>
									navigate('/uz_download', {
										state: { data: downloadData, user: data, tableData },
									})
								}
							>
								Шартномани юклаш
							</Button>
						)}
					</div>
				</div>
			</ViewItemStyle>
			<>
				{dollarLoading ? null : (
					<PaymentModal
						getRef={(r) => (paymentRef.current = r)}
						onConfirm={(form) => createPayment(form)}
						onClose={() => paymentRef.current?.close()}
						loading={isLoading}
						currencyRate={dollar}
					/>
				)}
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<AcceptCancelModal
					getRef={(r) => (acceptRef.current = r)}
					onConfirm={() => handleClose(DocEntry)}
					isLoading={isLoading3}
				/>
				<SuccessModal
					getRef={(r) => (successModalRef.current = r)}
					onConfirm={() => successModalRef.current?.close()}
				/>
				<NoDollar getRef={getNoDollarRef} />
			</>
		</Layout>
	)
}

export default ViewItem






