import styled from 'styled-components'
import colors from '../../assets/style/colors'
import BackImage from '../../assets/images/back.jpg'

const LoginStyle = styled.div`
	height: 100vh;
	padding: 20px;
	background-color: #f7f8fc;
	//background-image: url(${BackImage});
	background-size: cover;
	box-sizing: border-box;
	background-position: center center;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	.text {
		color: green;
	}
	.container {
		height: 95vh;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		box-shadow: 3px 3px 10px #cfcfcf;
		border-radius: 10px;
		background-color: #fffffff2;
		.img {
			width: 150px;
			height: 150px;
			margin-top: 10px;
		}
		.card {
			width: 100%;
			margin-bottom: 50px;
			text-align: center;
			.label {
				font-size: 20px;
				color: ${colors.mainColor};
			}
			.input {
				border: 2px solid ${colors.gray};
				font-size: 20px;
				padding: 10px;
				border-radius: 5px;
				color: ${colors.mainColor};
				margin-top: 10px;
			}
		}
		.btn {
			padding: 10px 30px;
			border-radius: 5px;
			background-color: ${colors.mainColor};
			color: #ffffff;
			font-size: 18px;
			border: none;
		}
	}
`
export default LoginStyle
