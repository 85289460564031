import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import Button from '../../Button'

import api from '../../../api'
import { useSelector } from 'react-redux'
import { ErrorModal } from '../index'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
	},
	overlay: {
		background: '#00000099',
	},
}

const AcceptCancelModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
}) => {
	const errorModalRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	useEffect(() => {
		const ref = {
			open: () => {
        setIsOpenModal(true)
      },
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])




	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
<div className="w-full">
<h1 className="font-semibold text-black">Вы действительно хотите отменить продажу?</h1>
					<div className="flex  items-center mt-10 justify-between">
						<button
							className="bg-red-500 text-white py-2 px-4 rounded-md btn"
							onClick={() => {
								onClose()
								setIsOpenModal(false)
							}}
						>
							{'Нет'}
						</button>
						<Button
							isLoading={isLoading}
							className={'btn'}
             onClick={onConfirm}
						>
							{'Да'}
						</Button>
					</div>
          </div>
			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
		</Modal>
	)
}

export default AcceptCancelModal
