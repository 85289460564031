import React from 'react'
import LayoutStyle from './LayoutStyle'
import DrawerMenu from '../DrawerMenu'

const Layout = ({ children }) => {
	return (
		<div className="">
			<DrawerMenu />
			{children}
		</div>
	)
}

export default Layout
