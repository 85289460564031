import styled from 'styled-components'

const DownloadStyle = styled.div`
	min-height: 100vh;
	padding: 20px;
	background-color: #dfdfdf;
	//box-sizing: border-box;
	margin: auto;
	.container {
		width: 960px !important;
		margin: auto;
		height: 100%;
		background-color: #ffffff;
		display: flex;
		padding: 20px 20px 20px 80px;
		margin-bottom: 20px;
		.topTitle {
			font-size: 16px;
			font-weight: 700;
			text-align: center;
			padding: 0 50px;
			padding: 10px 0;
		}
		.tableTitle {
			text-align: center;
			font-weight: 700;
		}
		.allCard {
			width: 100%;
		}
		.container2 {
			display: flex;
		}
		.topTitle2 {
			font-size: 16px;
			font-weight: 700;
			text-align: center;
			padding: 0 50px;
			padding: 10px 0;
		}
		.containerTable {
			width: 100%;
		}
		.between {
			display: flex;
			justify-content: space-between;
		}
		.halfCard {
			width: 50%;
			//border: 1px solid gray;
			.halfCardMini {
				padding: 0 10px;
				.centerTitle {
					font-weight: 700;
					text-align: center;
					margin: 40px 0 20px 0;
				}
				.topTitle {
					font-size: 16px;
					font-weight: 700;
					text-align: center;
					padding: 0 50px;
					padding: 10px 0;
				}
				p {
					padding: 0;
					margin: 5px 0 0 0;
				}
				.between {
					display: flex;
					justify-content: space-between;
				}
			}
		}
		table {
			border-collapse: collapse;
			width: 100%;
			margin-bottom: 30px;
			th,
			td {
				border: 1px solid gray;
				text-align: center;
				color: #000000;
			}
		}
		.someCard {
			width: 100%;
		}
	}
	.flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
		i {
			width: 300px;
			margin: 20px 0;
		}
	}
	input {
		padding: 0;
		margin-top: 5px;
		border: none;
		padding-bottom: 2px;
		font-size: 16px;
		border-bottom: 1px solid gray;
		width: 200px;
	}

	.flexEnd {
		display: flex;
		justify-content: flex-end;
	}
	p {
		font-size: 14px;
		color: #000000;
	}
`
export default DownloadStyle
