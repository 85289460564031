import React, { useEffect, useRef, useState } from 'react'
import CreateUserStyle from '../CreateUser/CreateUserStyle'
import Modal from 'react-modal'
import Button from '../../Button'

import { get } from 'lodash'
import api, { customFuncsApi } from '../../../api'
import { useSelector } from 'react-redux'
import customMainApi from '../../../api'
import { ErrorModal } from '../index'
import moment from 'moment'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
    maxHeight: '100vh', // To make modal content scrollable if it exceeds viewport height
    // overflowY: 'auto',
	},
	overlay: {
		background: '#00000099',
	},
}

const ReportsModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
	onUpdated = () => {},
}) => {
	const { getMe } = useSelector((state) => state.main)
	const errorModalRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [loading, setLoading] = useState(false)
	const [userFields, setUserFields] = useState([])
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
	const [item, setItem] = useState('')
	const [price, setPrice] = useState('')
	const [debt, setDebt] = useState('')
	const [docEntry, setDocEntry] = useState('')
	const [data, setData] = useState([])

	useEffect(() => {
		const ref = {
			open: (data) => {
				console.log(data.data)
				setData(data.data)
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}

		>
			<CreateUserStyle>
				<div className="card">
					<p className="title">
						{'Создать пользователя'}
						{'!'}
					</p>
					{data ? (
						<div className=" overflow-x-auto mb-8 mt-10">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											Код Товара
										</th>
										<th scope="col" className="px-6 py-4">
											Товар
										</th>
										<th scope="col" className="px-6 py-4">
											IMEI
										</th>
										<th scope="col" className="px-6 py-4">
											Цена
										</th>
										<th scope="col" className="px-6 py-4">
											От какого продавца
										</th>
										<th scope="col" className="px-6 py-4">
											Имя склада
										</th>
									</tr>
								</thead>
								<tbody>
									{data.map((v, i) => {
										return (
											<tr
												key={i}
												// onClick={() => viewItem(v)}
												className="bg-white border-b  hover:bg-gray-50 "
											>
												<td
													scope="row"
													className="px-6 py-4 font-medium text-gray-900 "
												>
													{get(v, 'ItemCode', '-')}
												</td>
												<td className="px-6 py-4">{get(v, 'ItemName', '-')}</td>
												<td className="px-6 py-4">
													{get(v, 'IntrSerial', '-')}
												</td>
												<td className="px-6 py-4">
													{get(v, 'CostTotal', '-')}
												</td>
												<td className="px-6 py-4">{get(v, 'CardName', '-')}</td>
												<td className="px-6 py-4">{get(v, 'WhsName', '-')}</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					) : (
						''
					)}
				</div>
			</CreateUserStyle>

			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
		</Modal>
	)
}

export default ReportsModal
