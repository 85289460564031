import { useState, useEffect } from 'react'

const CheckboxGroup = ({
	items,
	selectedItems,
	handleSelectSerialNumber,
	handleRemoveSerialNumber,
}) => {
	const handleSelectAll = (e) => {
		const newSelectedValues = e.target.checked
			? items.map((item) => item.IntrSerial)
			: []
		if (newSelectedValues.length > 0) {
			newSelectedValues
				.filter((item) => !selectedItems.includes(item))
				.forEach((item) => handleSelectSerialNumber(item))
		} else {
			items
				.filter((item) => selectedItems.includes(item.IntrSerial))
				.forEach((item) => handleRemoveSerialNumber(item.IntrSerial))
		}
	}

	const handleCheckboxChange = (e) => {
		const value = e.target.value
		const isChecked = e.target.checked

		if (isChecked) {
			handleSelectSerialNumber(value)
		} else {
			handleRemoveSerialNumber(value)
		}
	}

	return (
		<div>
			{items?.length === 0 && <p>Нет IMEI</p>}
			{items?.length > 0 && (
				<div>
					<label className={'flex gap-2'}>
						<input
							type="checkbox"
							checked={items?.length === selectedItems.length}
							onChange={handleSelectAll}
						/>
						Выбрать все
					</label>
					<div className={'flex flex-col gap-1 mt-2 pl-3'}>
						{items?.map((item, index) => (
							<label key={index} className={'flex gap-2'}>
								<input
									type="checkbox"
									value={item.IntrSerial}
									checked={selectedItems.includes(item.IntrSerial)}
									onChange={handleCheckboxChange}
								/>
								{item.IntrSerial}
							</label>
						))}
					</div>
				</div>
			)}
		</div>
	)
}

export default CheckboxGroup
