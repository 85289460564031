import styled from 'styled-components'
import colors from '../../assets/style/colors'

const ViewItemStyle = styled.div`
	min-height: 100vh;
	padding: 20px;
	padding-top: 30px;
	background-size: cover;
	box-sizing: border-box;
	background-position: center center;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	.text {
		color: green;
	}
	.container {
		min-height: 92vh;
		width: 100%;

		box-shadow: 3px 3px 10px #cfcfcf;
		border-radius: 10px;
		padding: 30px;
		background-color: #fffffff2;
		align-items: flex-start;

		.containerMini {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.img {
				width: 150px;
				height: 150px;
				margin-top: 10px;
			}
			.card {
				width: 100%;
				margin-bottom: 50px;
				text-align: center;
				.label {
					font-size: 20px;
					color: ${colors.mainColor};
				}
				.input {
					border: 2px solid ${colors.gray};
					font-size: 20px;
					padding: 10px;
					border-radius: 5px;
					color: ${colors.mainColor};
					margin-top: 10px;
				}
			}
			.btn {
				padding: 10px 30px;
				border-radius: 5px;
				background-color: ${colors.mainColor};
				color: #ffffff;
				font-size: 18px;
				border: none;
			}
		}
	}

	.leftCard {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
		.leftcardMini {
			width: 600px;
			border: 1px solid #d8d8d8;
			padding: 0 5px;
			border-radius: 5px;
		}
	}
	.rightCard {
		width: 60%;
	}
	.topTitle {
		text-align: center;
		margin: 0;
		font-weight: bold;
		font-size: 20px;
		width: 100%;
		margin-bottom: 10px;
	}

	.productTitle {
		font-size: 25px;
		font-weight: bold;
		color: #434343;
		padding: 0;
		margin: 0;
		text-align: center;
	}

	.flex {
		display: flex;
		width: 80px;
		display: flex;
		padding: 0 10px;
		margin-top: 10px;
		width: 100%;
		.btn {
			width: 35px;
			height: 35px;
			padding: 0;
			border-radius: 50px;
			margin-right: 10px;
		}
	}
	.topCard {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		.topCardMini {
			display: flex;
		}
		.topTitleInput {
			margin: 0;
		}
		.topInput {
			border: 2px solid ${colors.gray};
			font-size: 14px;
			padding: 10px;
			border-radius: 5px;
			color: #434343;
			width: 280px;
			height: 10px;
			margin-right: 20px;
		}
		.topInputDate {
			border: 2px solid ${colors.gray};
			font-size: 14px;
			padding: 10px;
			border-radius: 5px;
			color: #434343;
			width: 180px;
			height: 10px;
			margin-right: 20px;
		}
		.sellect {
			border: 2px solid ${colors.gray};
			font-size: 14px;
			padding: 5px 10px;
			border-radius: 5px;
			color: #434343;
			width: 180px;
			height: 33px;
			margin-right: 20px;
		}
	}
	.leftCard2 {
		width: 400px;
		height: 250px;
		border: 1px solid #d8d8d8;
		padding: 10px 0;
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.operatorTitle {
		margin: 0;
	}
	.operatorTitle2 {
		margin: 0;
	}
	.between {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 0 5px;
	}
	.topInput {
		border: 2px solid ${colors.gray};
		font-size: 14px;
		padding: 10px;
		border-radius: 5px;
		color: #434343;
		width: 280px;
		height: 10px;
		margin-right: 20px;
	}
`
export default ViewItemStyle
