import styled from 'styled-components'

const LaunchStyle = styled.div`
	.container {
		flex: 1;
		display: flex;
		background-color: #ffffff;
		justify-content: center;
		align-items: center;
		height: 100vh;
		.logo {
			animation-name: launchAnimation;
			animation-duration: 2s;
			font-size: 25px;
		}
	}
	@keyframes launchAnimation {
		from {
			transform: translateY(50px);
			scale: 0.9;
			font-size: 25px;
		}
		to {
			transform: translateY(-25px);
			scale: 1;
			font-size: 50px;
		}
	}
`
export default LaunchStyle
