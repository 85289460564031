import styled from 'styled-components'
import colors from '../../../assets/style/colors'

const UpdateUserStyle = styled.div`
	.card {
		background-color: #00000000;
		padding: 30px;
		border-radius: 5px;
		text-align: center;
		width: 400px;
		.input {
			border: 2px solid ${colors.gray};
			font-size: 15px;
			padding: 10px;
			border-radius: 5px;
			color: ${colors.mainColor};
			width: 90%;
			margin-bottom: 15px;
		}
	}
	.centerCard {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
	}
	.btnY {
		padding: 10px 30px;
		border-radius: 5px;
		border: 3px solid #0a4d68;
		color: #0a4d68;
		transition: 0.2s;
		font-weight: 600;
		font-size: 17px;
	}
	.btnY:hover {
		background-color: #0a4d68;
		color: white;
	}

	.btnN {
		padding: 10px 30px;
		border-radius: 5px;
		border: 3px solid red;
		color: red;
		transition: 0.2s;
		font-weight: 600;
		font-size: 17px;
	}
	.btnN:hover {
		background-color: red;
		color: white;
	}
	.radioBtnCard {
		width: 150px;
		text-align: left;
		margin: auto;
	}
`
export default UpdateUserStyle
